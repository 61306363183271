<template>
  <v-container fluid>
    <v-layout
      column
      align-center
    >
      <v-img
        src="../../public/VM Logo Gray-Blue-White RGB.jpg"
        alt="Valet Moving Logo"
        max-height="170"
        max-width="170"
      />
      <v-divider />
      <v-form>
        <v-text-field
          v-model="userEmailAddress"
          disabled
          readonly
        />
        <v-text-field
          v-model="newPassword"
          label="New Password"
          :type="showNewPassword ? 'text' : 'password'"
          :error-messages="passwordsMatchErrors"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showNewPassword = !showNewPassword"
        />
        <v-text-field
          v-model="confirmNewPassword"
          label="Confirm New Password"
          :type="showNewPassword ? 'text' : 'password'"
          :error-messages="passwordsMatchErrors"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showNewPassword = !showNewPassword"
        />
        <v-btn
          v-if="passwordsMatch"
          color="primary darken-1"
          class="text-center"
          :loading="requestPending"
          @click="submitRequest"
        >
          Reset Password
        </v-btn>
      </v-form>
    </v-layout>

  </v-container>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ResetForgottenPasswordComponent',
  data: () => ({
    requestPending: false,
    newPassword: null,
    confirmNewPassword: null,
    showNewPassword: false,
  }),
  computed: {
    userEmailAddress() {
      const queryParams = new URLSearchParams(window.location.search);

      return queryParams.get('un');
    },
    passwordsMatchErrors() {
      const errors = [];

      if (!this.$v.newPassword.$dirty && !this.$v.confirmNewPassword.$dirty) {
        return errors;
      }

      if (!this.$v.newPassword.required || !this.$v.confirmNewPassword.required) {
        errors.push('Both the password and confirmation are required');
      }

      if (this.newPassword !== this.confirmNewPassword && (this.newPassword == null || this.confirmNewPassword == null)) {
        errors.push('The passwords must match');
      }

      return errors;
    },
    passwordsMatch() {
      return this.newPassword == this.confirmNewPassword && this.$v.newPassword.required && this.$v.confirmNewPassword.required;
    }
  },
  validations() {
    return {
      newPassword: {
        required,
      },
      confirmNewPassword: {
        required,
      },
    };
  },
  methods: {
    async submitRequest() {
      this.requestPending = true;

      const queryParams = new URLSearchParams(window.location.search);

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/forgotPassword/attemptReset`;

      const body = {
        requestId: queryParams.get('rid'),
        userId: queryParams.get('uid'),
        authToken: queryParams.get('auth'),
        newPassword: this.newPassword,
      };

      axios({ url, data: body, method: 'POST'}).then(
        (response) => {
          if (response.data.successful) {
            this.$router.push({ name: 'login', params: { passwordResetSuccessful: true }});
          }
        },
        (error) => {
          console.log(error);
        },
      ).finally(() => {
        this.requestPending = false;
      });
    },
  },
};
</script>