<template>
  <v-app>
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
  </v-app>
</template>

<script>

export default {
  computed: {
    layout() {
      if (this.$route.meta.layout) {
        return `${this.$route.meta.layout}-layout`;
      }

      return 'default-layout';
    },
  },
};
</script>
