export default class User {
    constructor(id, email, password, firstName, lastName, phone) {
      this.id = id;
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.phone = phone;
      this.password = password;
    }
  }
  