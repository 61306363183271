<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout
        column
        align-center
      >
        <v-alert
          v-model="registrationSuccessful"
          type="success"
          transition="scale-transition"
        >
          You have successfully completed registration and may now log in.
        </v-alert>
        <v-alert
          v-model="passwordResetSuccessful"
          type="success"
          transition="scale-transition"
        >
          You have successfully reset your password and may now log in.
        </v-alert>
        <v-alert
          v-model="incorrectPassword"
          type="error"
          transition="scale-transition"
        >
          You have entered an incorrect password. Please try again.
        </v-alert>
        <v-img
          src="../../public/VM Logo Gray-Blue-White RGB.jpg"
          alt="Valet Moving"
          max-height="170"
          max-width="170"
        />
        <v-divider />
        <v-form>
          <v-text-field
            v-model="user.email"
            label="Email"
            required
          />
          <v-text-field
            v-model="user.password"
            label="Password"
            type="password"
            required
          />
          <v-row class="justify-center">
            <v-btn
              color="primary"
              class="justify-center"
              @click="submit"
            >
              Login
            </v-btn>
          </v-row>
          <br>
          <br>
          <v-btn
            class="text-xs"
            color="blue darken-1"
            text
            ripple
            @click="goToRegister"
          >
            <sub>First time? Click here to register</sub>
          </v-btn>
          <br>
          <br>
          <v-btn
            class="text-xs"
            color="blue darken-1"
            text
            ripple
            @click="goToForgotPassword"
          >
            <sub>Forgot Password?</sub>
          </v-btn>
        </v-form>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import User from '../models/user';

export default {
  name: 'LoginComponent',
  props: {
    registrationSuccessful: {
      type: Boolean,
      default: false,
    },
    passwordResetSuccessful: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: false,
    user: new User('', '', []),
    loading: false,
    message: '',
    incorrectPassword: false,
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.registrationSuccessful) {
      setTimeout(() => {
        this.registrationSuccessful = false;
      }, 3000);
    }
  },
  methods: {
    goToRegister() {
      this.$router.push({ name: 'register' });
    },
    goToForgotPassword() {
      this.$router.push({ name: 'forgotPassword' })
    },
    async submit() {
      if (this.user.email && this.user.password) {
        await this.$store.dispatch('auth/login', this.user).then(
          () => {
            this.$store.dispatch('auth/checkTermsAndConditionsVersion');
            this.$router.push('/client');
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data)
                || error.message
                || error.toString();
            this.incorrectPassword = true;
          },
        );
      }
    },
  },
};
</script>
