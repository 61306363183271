<template>
  <v-container fluid>
    <v-card class="elevation-10">
      <v-row class="ml-2">
        <v-col
          v-for="(img, idx) in assets"
          :key="idx"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="img.src ? img.src : img"
            class="grey lighten-2"
            @click="showSelectedImage(idx)"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </v-row>
            </template>
          </v-img>
          <v-checkbox
            v-if="editEnabled || editByDefault"
            v-model="selected"
            :value="img"
          />
        </v-col>
      </v-row>
      <vue-easy-lightbox
        v-if="assets.length > 0"
        :visible="visible"
        :imgs="assets"
        :index="index"
        @hide="handleHide"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'PhotoComponent',
  components: {
    VueEasyLightbox,
  },
  props: {
    ownerId: {
      type: String,
      default: '',
    },
    editEnabled: {
      type: Boolean,
      default: false,
    },
    defaultText: {
      type: String,
      default: 'Upload Additional Photos',
    },
    editByDefault: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    assets: [],
    uploadingImages: false,
    visible: false,
    selected: [],
    index: 0,
    deletingImages: false,
    newPhoto: null,
  }),
  watch: {
    ownerId() {
      if (this.ownerId) {
        this.getAssets(this.ownerId);
      }
    },
  },
  mounted() {
    this.getAssets(this.ownerId);
  },
  methods: {
    ...mapActions('apiClient', ['get', 'create', 'del']),
    handleHide() {
      this.visible = false;
    },
    getAsset(assetId) {
      const assetUrl = `${process.env.VUE_APP_API_BASE_URL}/assets/${assetId}`;
      return assetUrl;
    },
    showSelectedImage(idx) {
      this.index = idx;
      this.visible = true;
    },
    async getAssets(ownerId) {
      await this.get({ url: `/assets/item/${ownerId}` }).then(
        (response) => {
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              const assetUrl = `${process.env.VUE_APP_API_BASE_URL}/assets/${element}`;
              const a = { src: assetUrl, id: element };
              this.assets.push(a);
            });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    async uploadImages() {
      this.uploadingImages = true;

      const formData = new FormData();
      formData.append('uploadFile', this.newPhoto, this.newPhoto.name);

      await this.create({ url: `/assets/${this.ownerId}`, body: formData }).then(
        (response) => {
          const asset = {
            src: this.getAsset(response.data.assetId),
            id: response.data.assetId,
          };
          this.assets.push(asset);
          this.uploadingImages = false;
          this.newPhoto = null;
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>
