import '@mdi/font/css/materialdesignicons.css';
import Vuelidate from 'vuelidate';
import VueDateFilterFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueCurrencyFilter from 'vue-currency-filter';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import AppLayout from './layouts/AppLayout.vue';
import SimpleLayout from './layouts/SimpleLayout.vue';

Vue.config.productionTip = false;

Vue.use(vuetify, {
  iconfont: 'mdi',
});

Vue.use(VueDateFilterFormat);

Vue.use(VueFilterDateParse);

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: '00',
});

Vue.use(Vuelidate);

Vue.component('default-layout', AppLayout);
Vue.component('simple-layout', SimpleLayout);

Vue.filter('phone', (phone) => {
  if (!phone) return '';

  return phone.replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.prototype.$currency = (number) => Vue.options.filters.currency(number);

Vue.prototype.$inToFt = (val) => {
  const feet = Math.floor(val / 12);
  const inches = (val - (val * 12));

  return { feet, inches };
};

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
