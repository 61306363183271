import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.module';
import apiClient from './modules/apiClient';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    auth,
    apiClient,
  },
});
