import axios from 'axios';

const defaultOptions = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
};

const instance = axios.create(defaultOptions);

instance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization && !!localStorage.getItem('token')) {
      const newToken = localStorage.getItem('token');
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = newToken;
    }

    return config;
  },
  (error) => { Promise.reject(error); },
);

instance.interceptors.response.use(undefined, (error) => new Promise(() => {
  // eslint-disable-next-line no-underscore-dangle
  if (error.status === 403) {
    this.$store.dispatch('auth/logout');
  }
}));

export default instance;
