<template>
  <v-container fluid>
    <v-alert v-model="errorAlert" dismissible prominent type="error" transition="scale-transition">
      Something went wrong, please try again.
    </v-alert>
    <v-data-table :headers="headers" :items="projectItems" :items-per-page="-1">
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | dateParse("YYYY-MM-DD") | dateFormat("dddd, MMM D, YYYY") }}
      </template>
      <template v-slot:item.estimatedReceivedDate="{ item }">
        {{ item.estimatedReceivedDate | dateParse("YYYY-MM-DD") | dateFormat("dddd, MMM D, YYYY") }}
      </template>
      <template v-slot:item.estimatedDeliveryDate="{ item }">
        {{ item.estimatedDeliveryDate | dateParse("YYYY-MM-DD") | dateFormat("dddd, MMM D, YYYY") }}
      </template>
      <template v-slot:item.estimatedAdditionalCharges="{ item }">
        {{ item.estimatedAdditionalCharges | currency }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar class="mb-2" color="blue darken-1" flat dark>
          <v-row justify="center">
            <v-toolbar-title>
              <h1>{{ project.subClient.name }} - {{ project.projectName }}</h1>
            </v-toolbar-title>
          </v-row>
        </v-toolbar>
        <br />
        <v-row justify="center">
          <v-spacer />
          <v-col>
            <v-text-field rounded filled readonly label="Total cubic feet" v-model="totalCubicFeet" />
          </v-col>
          <v-spacer />
          <v-col>
            <v-text-field rounded filled readonly label="Estimated Project Total" v-model="estimatedTotal" />
          </v-col>
          <v-spacer />
        </v-row>
        <v-dialog v-model="newProjectItemDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-row justify="center">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                <v-icon dark left>mdi-plus-box</v-icon>
                Add a New Item to This Project
              </v-btn>
            </v-row>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="newProjectItemDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <new-cost-estimator-project-item-form :input-item="inputItem" @onCreateItem="createItem"
              @onUpdateItem="updateItem"></new-cost-estimator-project-item-form>
          </v-card>
        </v-dialog>
        <br />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewCostEstimatorProjectItemForm from "./NewCostEstimatorProjectItemForm.vue";

export default {
  name: "CostEstimatorProjectItems",
  components: {
    NewCostEstimatorProjectItemForm,
  },
  props: {
    projectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    project: {},
    projectItems: [],
    loadingItems: true,
    newProjectItemDialog: false,
    errorAlert: false,
    inputItem: null,
    headers: [
      // {
      //   text: 'Created On',
      //   value: 'createdAt',
      // },
      {
        text: 'Received by Valet Moving',
        value: 'estimatedReceivedDate',
      },
      {
        text: 'Install Date',
        value: 'estimatedDeliveryDate',
      },
      {
        text: 'Estimated Price',
        value: 'estimatedTotal',
      },
      {
        text: 'Chargeable Days',
        value: 'chargeableDays',
      },
      {
        text: 'Length (in inches)',
        value: 'estimatedLength',
      },
      {
        text: 'Width (in inches)',
        value: 'estimatedWidth',
      },
      {
        text: 'Height (in inches)',
        value: 'estimatedHeight',
      },
      {
        text: 'Estimated Cubic Feet',
        value: 'estimatedFinalCubicFootage',
      },
      {
        text: 'Additional Charges',
        value: 'estimatedAdditionalCharges',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      }
    ],
  }),
  created() {
    this.getProject();
    this.getProjectItems();
  },
  computed: {
    estimatedTotal() {
      return this.getProjectTotal();
    },
    totalCubicFeet() {
      return this.getTotalCubicFeet();
    },
  },
  methods: {
    ...mapActions("apiClient", ["get", "post", "del", "update"]),
    ...mapGetters("auth", ["getClientId"]),
    getProjectTotal() {
      let sum = 0.0;

      this.projectItems.forEach((e) => sum += e.estimatedTotal);

      return this.$currency(sum.toFixed(2));
    },
    getTotalCubicFeet() {
      let sum = 0.0;

      this.projectItems.forEach((e) => sum += e.estimatedFinalCubicFootage);

      return sum.toFixed(2);
    },
    async deleteItem(item) {
      this.loading = true;

      await this.del({ url: `/estimator/${this.projectId}/items/${item.itemId}` }).then(
        (result) => {
          if (result.data.successful) {
            this.getProjectItems();
          }
        },
        (error) => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    },
    editItem(item) {
      this.inputItem = item;

      this.newProjectItemDialog = true;
    },
    async updateItem(item) {
      this.loading = true;
      this.newProjectItemDialog = false;

      await this.update({ url: `/estimator/${this.projectId}`, body: item }).then(
        () => {
          this.getProjectItems();
        },
        (error) => {
          console.log(error);
        },
      ).finally(
        () => {
          this.loading = false;
        },
      );
    },
    async getProject() {
      this.loading = true;

      await this.get({ url: `/estimator/${this.projectId}` })
        .then(
          (response) => {
            this.project = response.data;
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    async createItem(item) {
      this.loading = true;
      this.newProjectItemDialog = false;

      await this.post({ url: `estimator/${this.projectId}/items`, body: item })
        .then(
          (response) => {
            this.projectItems.push(response.data);
          },
          (error) => {
            console.log(error);
            this.errorAlert = true;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    async getProjectItems() {
      this.loadingItems = true;

      await this.get({ url: `/estimator/${this.projectId}/items` })
        .then(
          (response) => {
            this.projectItems = response.data;
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.loadingItems = false;
          }, 2000);
        });
    },
  },
};
</script>
