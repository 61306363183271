<template>
  <v-form>
    <v-card-title>
      <div class="headline">
        Add a New Item To This Project
      </div>
    </v-card-title>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Estimated Date Received by Valet Moving
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          Estimated Date Delivered to Client
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3">
          Dimensions
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 4" step="4">
          Description
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 5" step="5">
          Additional Materials and Services
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div>
            <v-card-text>
              <v-row justify="center">
                <v-card-title>
                  What day will Valet Moving receive this item at their
                  warehouse?
                </v-card-title>
              </v-row>
              <v-spacer />
              <v-row justify="center">
                <v-date-picker v-model="item.estimatedReceivedDate">
                </v-date-picker>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row justify="space-around">
                <v-btn color="primary" @click="step = 2">
                  Next
                </v-btn>
              </v-row>
            </v-card-actions>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div>
            <v-card-text>
              <v-row justify="center">
                <v-card-title>
                  What day will Valet Moving deliver this item to the client?
                </v-card-title>
              </v-row>
              <v-spacer />
              <v-row justify="center">
                <v-date-picker v-model="item.estimatedDeliveryDate">
                </v-date-picker>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center">
                <v-btn color="primary" @click="step = 3">
                  Next
                </v-btn>
                <v-btn text @click="step = 1">
                  Back
                </v-btn>
              </v-row>
            </v-card-actions>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div>
            <v-row justify="center">
              <v-card-title>
                What are the estimated dimensions for this item?
              </v-card-title>
            </v-row>
            <v-row justify="space-around" style="margin-left: 40px; margin-right: 40px;">
              <v-card-text>
                <v-text-field v-model.number="item.estimatedLength" label="Estimated Length, in inches" type="number"
                  @input="$v.estimatedLength.$touch()" @blur="$v.estimatedLength.$touch()"
                  :error-messages="lengthErrors" />
                <v-text-field v-model.number="item.estimatedHeight" label="Estimated Height, in inches" type="number"
                  @input="$v.estimatedHeight.$touch()" @blur="$v.estimatedHeight.$touch()"
                  :error-messages="heightErrors" />
                <v-text-field v-model.number="item.estimatedWidth" label="Estimated Width, in inches" type="number"
                  @input="$v.estimatedWidth.$touch()" @blur="$v.estimatedWidth.$touch()"
                  :error-messages="widthErrors" />
                <v-text-field v-model.number="estimatedCubicFeet" label="Estimated Cubic Feet" disabled readonly />
                <v-text-field label="Estimated Cubic Feet with Packaging (figure above, plus 20%)"
                  v-model.number="estimatedFinalCubicFeet" disabled readonly />
              </v-card-text>
            </v-row>
            <v-card-actions>
              <v-row justify="center">
                <v-btn color="primary" @click="step = 4">
                  Next
                </v-btn>
                <v-btn text @click="step = 2">
                  Back
                </v-btn>
              </v-row>
            </v-card-actions>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div>
            <v-row justify="center">
              <v-card-title>
                Please add a description for this item
              </v-card-title>
            </v-row>
            <v-row justify="center" style="margin-left: 40px; margin-right: 40px;">
              <v-card-text>
                <v-textarea v-model="item.description" label="Description" outlined counter
                  :rules="[rules.lengthFifty]" />
              </v-card-text>
            </v-row>
            <v-card-actions>
              <v-row justify="center">
                <v-btn color="primary" @click="step = 5">
                  Next
                </v-btn>
                <v-btn text @click="step = 3">
                  Back
                </v-btn>
              </v-row>
            </v-card-actions>
          </div>
        </v-stepper-content>

        <v-stepper-content step="5">
          <div>
            <v-row justify="center">
              <v-card-title>
                Add any estimated additional materials or services from Valet
                Moving below.
              </v-card-title>
            </v-row>
            <v-row justify="space-around" style="margin-left: 40px; margin-right: 40px">
              <v-card-text>
                <v-text-field v-model.number="item.estimatedAdditionalCharges" label="Estimated Additional Charges"
                  type="number" prepend-inner-icon="mdi-currency-usd" @input="$v.estimatedAdditionalCharges.$touch()"
                  @blur="$v.estimatedAdditionalCharges.$touch()" :error-messages="estimatedAdditionalChargesErrors" />
              </v-card-text>
            </v-row>
            <v-card-actions>
              <v-row justify="center">
                <v-btn color="primary" @click="createItem">
                  Create
                </v-btn>
                <v-btn text @click="step = 4">
                  Back
                </v-btn>
              </v-row>
            </v-card-actions>
          </div>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-form>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";

export default {
  name: "NewCostEstimatorProjectItemFrom",
  props: {
    inputItem: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    step: 1,
    item: {
      itemId: null,
      projectId: null,
      estimatedReceivedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      estimatedDeliveryDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      description: null,
      estimatedLength: null,
      estimatedWidth: null,
      estimatedHeight: null,
      estimatedAdditionalCharges: null,
    },
    rules: {
      lengthFifty: (value) => {
        if (value) {
          return value.length <= 50 || "Max of 50 characters";
        }

        return true;
      },
    },
  }),
  computed: {
    estimatedCubicFeet() {
      if (
        this.item.estimatedHeight &&
        this.item.estimatedWidth &&
        this.item.estimatedLength
      ) {
        return (
          (this.item.estimatedHeight *
            this.item.estimatedWidth *
            this.item.estimatedLength) /
          1728
        ).toFixed(2);
      } else {
        return 0.0;
      }
    },
    estimatedFinalCubicFeet() {
      return (this.estimatedCubicFeet * 1.2).toFixed(2);
    },
    lengthErrors() {
      const errors = [];

      if (!this.$v.estimatedLength.$dirty) {
        return errors;
      }

      if (!this.$v.estimatedLength.numeric) {
        errors.push("Estimated Length must be numeric only");
      }

      if (!this.item.estimatedLength) {
        errors.push("Estimated Length is required");
      }

      return errors;
    },
    widthErrors() {
      const errors = [];

      if (!this.$v.estimatedWidth.$dirty) {
        return errors;
      }

      if (!this.$v.estimatedWidth.numeric) {
        errors.push("Estimated Width must be numeric only");
      }

      if (!this.item.estimatedWidth) {
        errors.push("Estimated Width is required");
      }

      return errors;
    },
    heightErrors() {
      const errors = [];

      if (!this.$v.estimatedHeight.$dirty) {
        return errors;
      }

      if (!this.$v.estimatedHeight.numeric) {
        errors.push("Estimated Height must be numeric only");
      }

      if (!this.item.estimatedHeight) {
        errors.push("Estimated Height is required");
      }

      return errors;
    },
    estimatedAdditionalChargesErrors() {
      const errors = [];

      if (!this.$v.estimatedAdditionalCharges.$dirty) {
        return errors;
      }

      if (!this.$v.estimatedAdditionalCharges.numeric) {
        errors.push("This field is numeric only");
      }

      return errors;
    },
  },
  mounted() {
    this.$v.$touch();
  },
  created() {
    if (this.inputItem !== null) {
      this.inputItem.estimatedDeliveryDate = new Date(this.inputItem.estimatedDeliveryDate);
      this.inputItem.estimatedReceivedDate = new Date(this.inputItem.estimatedReceivedDate);
      this.item = this.inputItem;
    }
  },
  validations: {
    estimatedLength: {
      required,
      numeric,
    },
    estimatedHeight: {
      required,
      numeric,
    },
    estimatedWidth: {
      required,
      numeric,
    },
    estimatedAdditionalCharges: {
      numeric,
    },
  },
  methods: {
    createItem() {
      this.item.estimatedReceivedDate = new Date(
        this.item.estimatedReceivedDate
      );
      this.item.estimatedDeliveryDate = new Date(
        this.item.estimatedDeliveryDate
      );

      if (this.inputItem !== null) {
        this.$emit("onUpdateItem", this.item);
      } else {
        this.$emit("onCreateItem", this.item);
      }

      this.item = {
        projectId: null,
        estimatedReceivedDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        estimatedDeliveryDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        description: null,
        estimatedLength: null,
        estimatedWidth: null,
        estimatedHeight: null,
        estimatedAdditionalCharges: null,
      };
      this.step = 1;
    },
  },
};
</script>
