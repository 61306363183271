<template>
  <v-container fluid>
    <v-layout
      column
      align-center
    >
      <v-img
        src="../../public/VM Logo Gray-Blue-White RGB.jpg"
        max-height="170"
        max-width="170"
      />
      <v-divider />
      <v-form>
        <v-alert
          v-model="success"
          dismissable
          prominent
          type="success"
          transition="scale-transition"
        >
          Password changed successfully!
        </v-alert>
        <v-text-field
          v-model="currentPassword"
          label="Current Password"
          type="password"
          required
          @blur="checkCurrentPassword"
        />
        <v-text-field
          v-model="newPassword"
          label="New Password"
          type="password"
          required
          :disabled="!currentPasswordCorrect"
        />
        <v-text-field
          v-model="confirmNewPassword"
          label="Confirm New Password"
          type="password"
          required
          :disabled="!currentPasswordCorrect"
        />
        <v-btn
          v-if="currentPasswordCorrect && passwordsMatch"
          color="error"
          @click="changePassword"
        >
          Change Password
        </v-btn>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ChangePasswordComponent',
  data: () => ({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    currentPasswordCorrect: false,
    newPasswordsMatch: false,
    success: false,
  }),
  computed: {
    passwordsMatch() {
      if (this.newPassword && this.confirmNewPassword) {
        return this.newPassword === this.confirmNewPassword;
      }

      return false;
    },
  },
  methods: {
    ...mapActions('apiClient', ['get', 'update']),
    checkCurrentPassword() {
      this.get({ url: '/users/checkCurrentPassword', params: { p: this.currentPassword } }).then(
        (response) => {
          this.currentPasswordCorrect = response.data.success;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    changePassword() {
      this.update({ url: '/users/changePassword', body: { pw: this.newPassword } }).then(
        (response) => {
          this.success = response.data.success;
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>
