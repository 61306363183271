<template>
  <v-container fluid>
    <v-layout
      column
      align-center
    >
      <v-img
        src="../../public/VM Logo Gray-Blue-White RGB.jpg"
        max-height="170"
        max-width="170"
      />
      <v-divider />
      <v-form>
        <v-text-field
          v-model="email"
          label="Email"
          :disabled="disableUsername"
          required
        />
        <v-text-field
          v-if="!codeMatches"
          v-model="loginCode"
          label="Login Code"
          required
          @change="checkCode"
          @blur="checkCode"
        />
        <v-text-field
          v-if="codeMatches"
          v-model="password"
          type="password"
          label="Password"
        />
        <v-text-field
          v-if="codeMatches"
          v-model="confirmPassword"
          label="Confirm Password"
          type="password"
          :rules="[passwordMatches]"
          required
        />
        <v-btn
          v-if="codeMatches"
          :disabled="!passwordMatches"
          @click="register"
        >
          Register
        </v-btn>
      </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RegisterComponent',
  data: () => ({
    codeMatches: false,
    email: '',
    loginCode: '',
    password: '',
    confirmPassword: '',
    success: false,
    disableUsername: false,
  }),
  computed: {
    passwordMatches() {
      if (this.password && this.confirmPassword && this.confirmPassword) {
        return this.password === this.confirmPassword;
      }

      return true;
    },
  },
  watch: {
    loginCode(val) {
      if (val && val !== '') {
        setTimeout(() => this.checkCode(), 300);
      }
    },
  },
  created() {
    const authCode = this.$route.query.token;
    const username = this.$route.query.un;

    if (username && authCode) {
      this.email = username;
      this.disableUsername = true;
      this.loginCode = authCode;
      this.doCheckCode(username, authCode);
    }
  },
  methods: {
    ...mapActions('apiClient', ['get', 'update']),
    checkCode() {
      this.doCheckCode(this.email, this.loginCode);
    },
    doCheckCode(email, authCode) {
      this.get({ url: '/users/checkCode', params: { e: email, lc: authCode } }).then(
        (response) => {
          this.codeMatches = response.data.match;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    register() {
      this.update({ url: '/users/setPassword', body: { email: this.email, code: this.loginCode, password: this.password } }).then(
        (response) => {
          if (response.data.success) {
            this.$router.push({ name: 'login', params: { registrationSuccessful: true } });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>
