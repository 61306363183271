export default function buildRequest(query) {
    let body = null;
    let queryParams = null;
    let endpoint = query.url;
  
    if (query.body) {
      body = query.body;
    }
  
    if (query.params) {
      queryParams = query.params;
    }
  
    if (query.id) {
      endpoint = `${endpoint}/${query.id}`;
    }
  
    return {
      endpoint,
      body,
      queryParams,
    };
  }
  