<template>
  <v-container>
    <v-autocomplete
      v-model="selectedItems"
      :items="items"
      :loading="searchLoading"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      allow-overflow
      menu-props="auto, overflowY"
      label="Search by ICN"
      dense
      item-text="name"
      item-value="item"
      prepend-icon="mdi-magnify"
      return-object
      chips
      small-chips
      deletable-chips
      multiple
    />
    <template>
      <div class="text-center">
        <v-btn
          :loading="pdfLoading"
          color="primary"
          dark
          @click="getPdf"
        >
          Print Table
        </v-btn>
        <v-dialog
          v-model="totalsDialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red lighten-2"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Totals
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  align-self="start"
                >
                  Size Charges:
                </v-col>
                <v-col
                  align-self="end"
                >
                  <strong>{{ sizeChargeTotal | currency }}</strong>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  align-self="start"
                >
                  Services Total:
                </v-col>
                <v-col
                  align-self="end"
                >
                  <strong>{{ additionalServicesTotal | currency }}</strong>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  align-self="start"
                >
                  Materials Total:
                </v-col>
                <v-col
                  align-self="end"
                >
                  <strong>{{ additionalMaterialsTotal | currency }}</strong>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col
                  align-self="start"
                >
                  Grand Total:
                </v-col>
                <v-col
                  align-self="end"
                >
                  <span style="color:red;">
                    <strong>{{ grandTotal | currency }}</strong>
                  </span>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-btn
                  text
                  @click="totalsDialog = false"
                >
                  Cancel
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog> 
      </div>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              max-width="290"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  label="Calculate Charges on Date"
                  clearable
                  @click:clear="date = null"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                width="290"
                scrollable
                @change="saveStartDate"
              />
            </v-menu>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-data-table
      ref="tableContent"
      :headers="headers"
      :items="charges"
      :items-per-page="-1"
      class="elevation-1"
    >
      <template v-slot:item.cubicFeet="{ item } ">
        {{ item.cubicFeet }} ft<sup>3</sup>
      </template>
      <template v-slot:item.sizeCharge="{ item } ">
        <span>{{ item.sizeCharge | currency }}</span>
      </template>
      <template v-slot:item.additionalServices="{ item }">
        <span>{{ item.additionalServices | currency }}</span>
      </template>
      <template v-slot:item.additionalMaterials="{ item }">
        <span>{{ item.additionalMaterials | currency }}</span>
      </template>
      <template v-slot:item.minimumWeeklyCharge="{ item }">
        <v-simple-checkbox
          v-model="item.minimumWeeklyCharge"
          disabled
        />
      </template>
      <template v-slot:item.totalCharge="{ item }">
        <span>{{ item.totalCharge | currency }}</span>
      </template>
      <template v-slot:item.actions="props">
        <td
          class="justify-center"
          layout
          px-0
        >
          <v-btn
            text
            color="teal accent-4"
            @click="goToItem(props.item)"
          >
            Edit
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'InventoryChargesComponent',
  data: () => ({
    clientId: '',
    selectedItems: [],
    searchLoading: false,
    pdfLoading: false,
    entries: [],
    search: null,
    date: null,
    startDateMenu: false,
    successDialog: false,
    errorDialog: false,
    confirmDialog: false,
    success: false,
    timerId: null,
    test: 'table',
    totalsDialog: false,
    charges: [],
    headers: [
      {
        text: 'ICN',
        value: 'item.icn',
      },
      {
        text: 'Received',
        value: 'receivedDate',
      },
      {
        text: 'Charges Start',
        value: 'chargeStartDate',
      },
      {
        text: 'Chargeable Days',
        value: 'chargeableDays',
      },
      {
        text: 'Cubic Feet',
        value: 'cubicFeet',
      },
      {
        text: 'Minimum Daily Charge',
        value: 'minimumWeeklyCharge',
      },
      {
        text: 'Storage charge',
        value: 'sizeCharge',
      },
      {
        text: 'Additional Services',
        value: 'additionalServices',
      },
      {
        text: 'Additional Materials',
        value: 'additionalMaterials',
      },
      {
        text: 'Total',
        value: 'totalCharge',
      },
      {
        text: '',
        value: 'actions',
        sortable: 'false',
      },
    ],
  }),
  created() {
      this.clientId = this.getClientId();
  },
  computed: {
    fields() {
      if (!this.entries) {
        return [];
      }

      return Object.keys(this.selectedItems).map((key) => ({
        key,
        value: this.selectedItems[key] || 'n/a',
      }));
    },
    items() {
      return this.entries.map((entry) => (entry));
    },
    additionalServicesTotal() {
      return this.sumField('additionalServices');
    },
    additionalMaterialsTotal() {
      return this.sumField('additionalMaterials');
    },
    sizeChargeTotal() {
      return this.sumField('sizeCharge');
    },
    grandTotal() {
      return this.sumField('totalCharge');
    },
  },
  watch: {
    search(val) {
      this.searchDebounced(val);
    },
    selectedItems(val, oldVal) {
      this.search = '';

      const adds = [
        ...val.filter((x) => !oldVal.includes(x)),
      ];

      const removes = [
        ...oldVal.filter((x) => !val.includes(x)),
      ];

      if (removes.length > 0) {
        this.charges = this.charges.filter((c) => !removes
          .map((r) => r.id).includes(c.item.itemId));
      }

      if (adds.length > 0) {
        adds.forEach((a) => this.getCharge(a.id));
      }
    },
  },
  methods: {
    ...mapActions('apiClient', ['get', 'post', 'getFile']),
    ...mapGetters('auth', ['getClientId']),
    sumField(key) {
      return this.charges.reduce((a, b) => a + (b[key] || 0), 0);
    },
    async searchDebounced(query) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.doSearch(query);
      }, 300);
    },
    async doSearch(query) {
      if (!query) {
        return;
      }

      this.searchLoading = true;

      await this.get({ url: `/inventory/autocomplete/${this.clientId}`, params: { icn: query, active: true } }).then(
        (response) => {
          if (response.data !== null && response.data.length > 0) {
            this.entries = response.data;
          }
        },
        (error) => {
          console.log(error);
        },
      );

      this.searchLoading = false;
    },
    saveStartDate(date) {
      this.$refs.startDateMenu.save(date);
      this.date = date;
      this.getCharges();
    },
    async getPdf() {
      this.pdfLoading = true;

      if (!this.charges) {
        return;
      }

      const chargeIds = {
        ids: [],
      };

      chargeIds.ids = this.charges.map((c) => c.item.itemId);

      let url = '/inventory/charges/download';

      if (this.date) {
        url += `?date=${this.date}`;
      }

      await this.post({ url, body: chargeIds }).then(
        (response) => {
          const arrayBuffer = this.base64ToArrayBuffer(response.data);
          this.createAndDownloadBlobFile(arrayBuffer, 'charge-report.pdf');
        },
        (error) => {
          console.log(error);
        },
      );

      this.pdfLoading = false;
    },
    base64ToArrayBuffer(data) {
      const bString = window.atob(data);
      const bLength = bString.length;
      const bytes = new Uint8Array(bLength);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < bLength; i++) {
        const ascii = bString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    createAndDownloadBlobFile(body, filename) {
      const blob = new Blob([body]);
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async markItemsBilled() {
      if (!this.charges) {
        return;
      }

      const chargeIds = {
        ids: [],
      };

      chargeIds.ids = this.charges.map((c) => c.item.itemId);

      await this.post({ url: '/inventory/billed', body: chargeIds }).then(
        () => {
          this.success = true;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    async getCharges() {
      if (!this.charges) {
        return;
      }

      const chargeIds = {
        ids: [],
      };

      chargeIds.ids = this.charges.map((c) => c.item.itemId);

      let url = '/inventory/charges';

      if (this.date) {
        url += `?date=${this.date}`;
      }

      await this.post({ url, body: chargeIds, params: { date: this.date } }).then(
        (response) => {
          this.charges = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    async getCharge(itemId) {
      if (!itemId) {
        return;
      }

      await this.get({ url: `/inventory/${itemId}/charges`, params: { date: this.date } }).then(
        (response) => {
          this.charges.push(response.data);
        },
        (error) => {
          console.log(error);
        },
      );
    },
    goToItem(item) {
      const route = this.$router.resolve({ name: 'inventoryEdit', params: { itemId: item.item.itemId } });
      window.open(route.href, '_blank');
    },
    showConfirmDialog() {
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    reset() {
      this.$router.go();
    },
  },
};
</script>
