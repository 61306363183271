<template>
  <v-container>
    <v-alert
      v-model="warnDialog"
      dismissible
      prominent
      type="warning"
      transition="scale-transition"
    >
      Changing the client, subclient, or date, will change this item's ICN,
      requiring it to be relabeled
    </v-alert>
    <v-alert
      v-model="successDialog"
      dismissable
      prominent
      type="success"
      transition="scale-transition"
    >
      Item successfully saved!
    </v-alert>
    <v-alert
      v-model="deleteDialog"
      dismissable
      prominent
      type="success"
      transition="scale-transition"
    >
      Item successfully deleted!
    </v-alert>
    <v-alert
      v-model="errorDialog"
      dismissible
      prominent
      type="error"
      transition="scale-transition"
    >
      Something went wrong
    </v-alert>
    <v-alert
      v-model="editing"
      dismissible
      prominent
      type="success"
      transition="scale-transition"
    >
      Editing enabled
    </v-alert>
    <v-card
      v-if="!loading"
      class="elevation-0"
    >
      <v-row>
        <v-row class="ml-2">
          <v-col>
            <span>
              <h1>
                {{ item.icn }}
              </h1>
            </span>
            <span
              v-if="!active"
            >
              <h1 style="color: red;">
                Inactive
              </h1>
            </span>
          </v-col>
          <v-spacer />
          <v-col
            class="mr-2 text-right"
          >
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col class="col-md-6 elevation-10">
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                max-width="290px"
                transition="slide-x-transition"
                :disabled="!editing"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    v-bind="attrs"
                    label="Received Date"
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon
                        :key="`icon-edit-date-${editingDate}`"
                        :color="editingDate ? 'success' : 'danger'"
                        :disabled="!editing"
                        @click="toggleDateEdit()"
                        v-text="editingDate ? 'mdi-lock-open' : 'mdi-lock'"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  width="290"
                  :max="new Date().toISOString().substr(0, 10)"
                  :readonly="!editing"
                >
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                  <v-btn
                    text
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <client-auto-complete
                :client="item.client"
                :locked="true"
                :disable-editing="!editing"
                @editingEnabled="showWarning"
                @onClickClient="updateClient"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <client-auto-complete
                :client="item.subClient"
                :subclient="true"
                :locked="true"
                :disable-editing="!editing"
                @editingEnabled="showWarning"
                @onClickClient="updateSubClient"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-textarea
                v-model="item.description"
                rows="1"
                auto-grow
                :readonly="!editing"
                label="Description"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <manufacturer-auto-complete
                :mfg="item.manufacturer"
                :disable-editing="!editing"
                :locked="true"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-sm-2">
              <v-text-field
                v-model.number="item.length"
                label="Length (in inches)"
                type="number"
                :readonly="!editing"
              />
            </v-col>
            <v-col class="col-sm-2">
              <v-text-field
                v-model.number="item.width"
                label="Width (in inches)"
                type="number"
                :readonly="!editing"
              />
            </v-col>
            <v-col class="col-sm-2">
              <v-text-field
                v-model.number="item.height"
                label="Height (in inches)"
                type="number"
                :readonly="!editing"
              />
            </v-col>
            <v-col class="col-sm-2">
              <v-text-field
                v-model.number="cubicFeet"
                label="Cubic Feet"
                readonly
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-combobox
                v-model="item.locationStatus"
                :items="locationOptions"
                :readonly="!editing"
                label="Location Status"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-text-field
                v-model="chargesBeginDate"
                readonly
                label="Date Charges Begin"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-text-field
                v-model.number="dailyCharge"
                label="Daily Storage Rate"
                readonly
              >
                <template v-slot:append>
                  {{ chargesToDate.minimumWeeklyCharge
                    ? 'Minimum Daily Rate' : 'Per Cubic Foot' }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-text-field
                v-model.number="totalCharge"
                label="Total Charges to Date"
                readonly
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <span><u>Additional Services Summary:</u></span>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-textarea
                v-model="item.additionalServicesNotes"
                rows="1"
                auto-grow
                :readonly="!editing"
                label="Description"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-select
                v-model="item.additionalServices"
                :items="servicesOptions"
                :readonly="!editing"
                item-text="title"
                item-value="value"
                label="Additional Services"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <span><u>Pallet/Crate Disposal</u></span>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-3">
              <v-select
                v-model="item.palletDisposalCount"
                :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]"
                :readonly="!editing"
              />
            </v-col>
            <v-col class="col-md-5 mt-6">
              @ {{ 19 | currency }} = {{ chargesToDate.palletDisposalCharge | currency }}
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <span><u>Materials Summary:</u></span>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-textarea
                v-model="item.additionalMaterialsNotes"
                rows="1"
                auto-grow
                :readonly="!editing"
                label="Description"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-combobox
                v-model="item.additionalMaterials"
                :items="materialsOptions"
                :readonly="!editing"
                label="Additional Materials"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-textarea
                v-model="item.generalNotes"
                rows="1"
                auto-grow
                :readonly="!editing"
                label="General Notes"
              />
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col class="col-md-8">
              <v-select
                v-model="item.billed"
                :items="billingOptions"
                :readonly="!editing"
                item-text="title"
                item-value="value"
                label="Billing Status"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col-md-6">
          <photo-component
            :owner-id="itemId"
            :edit-enabled="editing"
          />
        </v-col>
      </v-row>
    </v-card>
    <div
      v-else
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex';
import ClientAutoComplete from './ClientAutoComplete.vue';
import ManufacturerAutoComplete from './ManufacturerAutoComplete.vue';
import PhotoComponent from './PhotoComponent.vue';

export default {
  name: 'InventorySummaryForm',
  components: {
    ClientAutoComplete,
    ManufacturerAutoComplete,
    PhotoComponent,
  },
  props: {
    itemId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    assets: [],
    active: true,
    editing: false,
    deleteEnabled: false,
    editingDate: false,
    newFiles: [],
    newFilesToAdd: [],
    uploadingImages: false,
    deletingImages: false,
    uploadedCount: 0,
    selected: [],
    index: 0,
    menu: false,
    date: null,
    loading: true,
    visible: false,
    warnDialog: false,
    successDialog: false,
    errorDialog: false,
    deleteDialog: false,
    chargesToDate: {},
    item: {
      client: {
        name: '',
      },
      subClient: {
        name: '',
      },
      manufacturer: {
        name: '',
      },
      length: 0.0,
      width: 0.0,
      height: 0.0,
      description: '',
      additionalMaterials: 0.0,
      additionalMaterialsNotes: '',
      additionalServices: 0.0,
      additionalServicesNotes: '',
      generalNotes: '',
    },
    servicesOptions: [
      { title: 'n/a', value: 0 },
      { title: '1/4 Hour ($24.75)', value: 24.75 },
      { title: '1/2 Hour ($49.50)', value: 49.50 },
      { title: '3/4 Hour ($74.25)', value: 74.25 },
      { title: '1 Hour ($99.00)', value: 99.00 },
    ],
    billingOptions: [
      { title: 'Billed', value: true },
      { title: 'Unbilled', value: false },
    ],
    locationOptions: [
      'In Warehouse',
      'Picked Up From Loading Dock',
      'Delivered',
    ],
    materialsOptions: [5.00, 9.00, 14.00, 19.00, 24.00],
  }),
  computed: {
    dateFormatted() {
      return new Date(this.date).toISOString().substr(0, 10);
    },
    cubicFeet() {
      const exactCubicFeet = (this.item.length / 12)
      * (this.item.width / 12)
      * (this.item.height / 12);
      const roundedCubicFeet = (Math.round(exactCubicFeet * 4) / 4).toFixed(2);
      return roundedCubicFeet;
    },
    chargesBeginDate() {
      const dt = new Date(this.date);
      dt.setDate(dt.getDate() + 7);
      return new Date(dt).toISOString().substr(0, 10);
    },
    dailyCharge() {
      return this.chargesToDate.minimumWeeklyCharge
        ? this.$currency(0.50) : this.$currency(this.cubicFeet * this.item.client.chargeRate);
    },
    totalCharge() {
      return this.$currency(this.chargesToDate.totalCharge);
    },
    palletDisposalCharge() {
      return this.$currency(this.chargesToDate.palletDisposalCharge);
    },
  },
  created() {
    this.item = this.getItem();
  },
  methods: {
    ...mapActions('apiClient', ['get', 'update', 'create', 'del']),
    showImage(index) {
      this.index = index;
      this.visible = true;
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    toggleDateEdit() {
      this.editingDate = !this.editingDate;

      if (this.editingDate) {
        this.showWarning();
      }
    },
    convertToRoundedFeet(val) {
      return (Math.round((val / 12) * 4) / 4).toFixed(2);
    },
    handleHide() {
      this.visible = false;
    },
    updateClient(client) {
      this.item.client = client;
    },
    updateSubClient(subClient) {
      this.item.subClient = subClient;
    },
    showWarning() {
      this.warnDialog = true;

      setTimeout(() => { this.warnDialog = false; }, 10000);
    },
    showSelectedImage(idx) {
      this.index = idx;
      this.visible = true;
    },
    getAsset(assetId) {
      const assetUrl = `${process.env.VUE_APP_API_BASE_URL}/assets/${assetId}`;
      return assetUrl;
    },
    async uploadImages() {
      this.uploadingImages = true;

      await this.newFiles.reduce(async (memo, file) => {
        await memo;
        const formData = new FormData();
        formData.append('uploadFile', file, file.name);

        await this.create({ url: `/assets/${this.item.itemId}`, body: formData }).then(
          (response) => {
            const asset = {
              src: this.getAsset(response.data.assetId),
              id: response.data.assetId,
            };
            // const newAssetUrl = this.getAsset(response.data.assetId);
            this.assets.push(asset);
            this.newFiles.pop(file);
          },
          (error) => {
            console.log(error);
          },
        );

        const newUploadedCount = this.uploadedCount + 1;
        console.log(`New uploaded count: ${newUploadedCount}`);
        this.uploadedCount = newUploadedCount;
      }, undefined);

      this.uploadingImages = false;
      this.newFiles = [];
    },
    async getItem() {
      this.loading = true;

      await this.get({ url: '/inventory', id: this.itemId }).then(
        (response) => {
          this.item = response.data;
          this.date = new Date(this.item.receivedDate).toISOString().substr(0, 10);
          this.getAssets(response.data.itemId);
          this.getCharges(response.data.itemId);
          this.active = response.data.active;
        },
        (error) => {
          console.log(error);
        },
      );

      this.loading = false;
    },
    async getAssets(itemId) {
      await this.get({ url: `/assets/item/${itemId}` }).then(
        (response) => {
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              const assetUrl = `${process.env.VUE_APP_API_BASE_URL}/assets/${element}`;
              const a = { src: assetUrl, id: element };
              this.assets.push(a);
            });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    getCharges(itemId) {
      this.get({ url: `/inventory/${itemId}/charges` }).then(
        (response) => {
          this.chargesToDate = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    addFiles(files) {
      files.forEach((f) => this.newFilesToAdd.push(f));
      this.newFiles = this.newFilesToAdd;
    },
  },
};
</script>
