<template>
  <v-card>
    <success-alert
      v-if="successfullyCreated"
      :name="manufacturer.name"
    />
    <v-card-title
      primary-title
      style="flex-direction: column;"
    >
      <div class="headline">
        Create Manufacturer
      </div>
      <div>
        <v-form
          @submit.prevent="onCreateManufacturer"
          @keydown.prevent.enter
        >
          <v-text-field
            v-model="manufacturer.name"
            :error-messages="mfgNameErrors"
            label="Name"
            required
            @input="$v.manufacturer.name.$touch()"
            @blur="$v.manufacturer.name.$touch()"
          />
          <v-select
            v-model="manufacturer.manufacturerType"
            :items="manufacturerTypes"
            label="Type"
          />
          <v-btn
            type="submit"
            :loading="submissionPending"
          >
            Create
          </v-btn>
        </v-form>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import Manufacturer from '../models/manufacturer';
import SuccessAlert from './SuccessAlert.vue';

export default {
  name: 'NewManufacturerForm',
  components: {
    SuccessAlert,
  },
  data: () => ({
    dialog: false,
    fullPage: true,
    submissionPending: false,
    successfullyCreated: false,
    manufacturer: new Manufacturer(),
    manufacturerTypes: [
      'Manufacturer',
      'Antique',
      'Shop',
    ],
  }),
  computed: {
    mfgNameErrors() {
      const errors = [];

      if (!this.$v.manufacturer.name.$dirty) {
        return errors;
      }

      if (!this.$v.manufacturer.name) {
        errors.push('Please enter a valid, unique name');
      }

      if (!this.$v.manufacturer.name.isUnique) {
        errors.push('This name is already in use');
      }

      return errors;
    },
  },
  validations: {
    manufacturer: {
      name: {
        required,
        async isUnique(value) {
          if (value === '') {
            return true;
          }

          if (this.$v.manufacturer.name) {
            const unique = await this.validateName();
            return Boolean(unique);
          }

          return false;
        },
      },
    },
  },
  methods: {
    ...mapActions('apiClient', ['create', 'get']),
    async validateName() {
      let valid = true;

      await this.get({ url: '/manufacturers/checkName', params: { name: this.manufacturer.name } }).then(
        (response) => {
          valid = !response.data.used;
        },
        (error) => {
          console.log(error);
          valid = false;
        },
      );

      return valid;
    },
    async onCreateManufacturer() {
      this.manufacturer.manufacturerType = this.manufacturer.manufacturerType.toUpperCase();
      this.submissionPending = true;
      await this.create({ url: '/manufacturers', body: this.manufacturer }).then(
        (response) => {
          this.$emit('manufacturerCreated', response.data);
          this.successfullyCreated = true;
        },
        (error) => {
          console.log(error);
        },
      );

      this.submissionPending = false;
    },
  },
};
</script>
