<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout
        column
        align-center
      >
        <v-alert
          v-model="resetRequestSuccessful"
          type="success"
          transition="scale-transition"
        >
          If a user exists with the email provided, an email containing password reset instructions will be sent to the registered address.
        </v-alert>
        <v-img
          src="../../public/VM Logo Gray-Blue-White RGB.jpg"
          alt="Valet Moving Logo"
          max-height="170"
          max-width="170"
        />
        <v-divider />
        <v-form>
          <v-text-field
            v-model="userEmail"
            :error-messages="userEmailErrors"
            label="Email"
            required
            @input="$v.userEmail.$touch()"
            @blur="$v.userEmail.$touch()"
          />
          <v-btn
            v-if="addressValid"
            color="primary darken-1"
            class="text-center"
            :loading="requestPending"
            @click="submitRequest"
          >
            Request Password Reset
          </v-btn>
        </v-form>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import axios from 'axios';
import {
  required, email
} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  name: 'ForgotPasswordComponent',
  data: () => ({
    userEmail: null,
    resetRequestSuccessful: false,
    requestPending: false,
  }),
  computed: {
    userEmailErrors() {
      const errors = [];

      if (!this.$v.userEmail.$dirty) {
        return errors;
      }

      if (!this.$v.userEmail.required) {
        errors.push('Email is required');
      }

      if (!this.$v.userEmail.email) {
        errors.push('Please enter a valid email address');
      }

      return errors;
    },
    addressValid() {
      return this.userEmail !== null && this.$v.userEmail.required && this.$v.userEmail.email;
    },
  },
  validations() {
    return {
      userEmail: {
        required,
        email
      },
    };
  },
  methods: {
    ...mapActions('apiClient', ['get']),
     async submitRequest() {
      this.requestPending = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/forgotPassword/req?email=${this.userEmail}`;

      // Have to do this manually since everything going through the api client
      // invokes the interceptors to set the auth token, which doesn't exist
      // if we're resetting our password
      axios({ url, method: 'GET' }).then(
        (response) => {
          if (response.data.successful) {
            this.resetRequestSuccessful = true;
          }
        },
        (error) => {
          console.log(error);
        },
      ).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.requestPending = false;
      });
    },
  },
};
</script>