<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="clientContacts"
      :items-per-page="-1"
      :search="search"
      dense
      @click:row="editItem"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
        <v-dialog
          v-model="editDialog"
          max-width="600px"
          @click:outside="close"
        >
          <v-card>
            <v-card-title>{{ formTitle }}</v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="editedItem.firstName"
                  label="First Name"
                />
                <v-text-field
                  v-model="editedItem.lastName"
                  label="Last Name"
                />
                <v-text-field
                  v-model="editedItem.email"
                  label="Email"
                />
                <phone-component
                  :input="editedItem.phone1"
                  label="Phone 1"
                  @phoneSet="setPhone1"
                />
                <phone-component
                  :input="editedItem.phone2"
                  :required="false"
                  label="Phone 2"
                  @phoneSet="setPhone2"
                />
                <phone-component
                  :input="editedItem.phone3"
                  :required="false"
                  label="Phone 3"
                  @phoneSet="setPhone3"
                />
                <v-text-field
                  v-model="editedItem.title"
                  label="Position"
                />
              </v-container>
              <photo-component
                v-if="editedItem.clientContactId"
                :owner-id="editedItem.clientContactId"
                default-text="Upload a Contact Photo"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.phone1="{ item }">
        {{ item.phone1 | phone }}
      </template>
      <template v-slot:item.phone2="{ item } ">
        {{ item.phone2 | phone }}
      </template>
      <template v-slot:item.phone3="{ item }">
        {{ item.phone3 | phone }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

import PhoneComponent from './PhoneComponent.vue';
import PhotoComponent from './PhotoComponent.vue';

export default {
  name: 'ClientContacts',
  components: {
    PhoneComponent,
    PhotoComponent,
  },
  props: {
    clientId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isFullPage: true,
    noData: false,
    editedIndex: -1,
    editedItem: {
      client: {
        clientId: '',
      },
      firstName: '',
      lastName: '',
      email: '',
      phone1: '',
      phone2: '',
      phone3: '',
      title: '',
    },
    // editedItem: {},
    editDialog: false,
    client: {},
    search: '',
    headers: [
      {
        text: 'First Name',
        value: 'firstName',
      },
      {
        text: 'Last Name',
        value: 'lastName',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Phone 1',
        value: 'phone1',
      },
      {
        text: 'Phone 2',
        value: 'phone2',
      },
      {
        text: 'Phone 3',
        value: 'phone3',
      },
      {
        text: 'Title',
        value: 'title',
      },
    ],
    clientContacts: [],
  }),
  computed: {
    formTitle() {
      if (this.editedItem && this.editedItem.firstName && this.editedItem.lastName) {
        return `${this.editedItem.firstName} ${this.editedItem.lastName}`;
      // eslint-disable-next-line no-else-return
      } else {
        return `New contact for ${this.client.name}`;
      }
    },
  },
  mounted() {
    this.get({ url: '/clients', id: this.clientId }).then(
      (response) => {
        this.client = response.data;
      },
      (error) => {
        console.log(error);
      },
    );
    this.getContacts();
  },
  methods: {
    ...mapActions('apiClient', ['get', 'create', 'update', 'del']),
    editItem(item) {
      this.editedIndex = this.clientContacts.indexOf(item);
      this.editedItem = item;
      this.editDialog = true;
    },
    close() {
      this.editDialog = false;
      this.editedItem = {};
    },
    setPhone1(val) {
      this.editedItem.phone1 = val;
    },
    setPhone2(val) {
      this.editedItem.phone2 = val;
    },
    setPhone3(val) {
      this.editedItem.phone3 = val;
    },
    addClientContact(contact) {
      this.clientContacts.push(contact);
    },
    getContacts() {
      this.get({ url: 'contacts/client', id: this.clientId }).then(
        (response) => {
          this.clientContacts = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>
