import axios from 'axios';
import jwtDecode from 'jwt-decode';

const auth = {
  namespaced: true,
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    tcCurrent: localStorage.getItem('tcCurrent') || false,
  },
  actions: {
    async checkTermsAndConditionsVersion({ commit }) {
      const token = localStorage.getItem('token');

      if (token) {

        const decoded = jwtDecode(token);

        if (decoded.tcVersion) {
          const config = {
            headers: {
              Authorization: localStorage.getItem('token')
            }
          }
  
          const url = `${process.env.VUE_APP_API_BASE_URL}/users/terms`;
  
          axios.get(url, config).then(
            (response) => {
              if (response.data.successful) {
                commit('tc_check_success');
              } else {
                commit('tc_check_failure');
              }
            },
            (error) => {
              console.log(error);
              commit('tc_check_failure');
            }
          )
        } else {
          commit('tc_check_failure');
        }
      } else {
        return commit('tc_check_failure');
      }
    },
    async login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({ url: `${process.env.VUE_APP_API_BASE_URL}/login`, data: { email: user.email, password: user.password }, method: 'POST' }).then((resp) => {
          const token = resp.headers.authorization;
          localStorage.setItem('token', token);
          commit('auth_success', token);
          resolve(resp);
        }).catch((err) => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('tcCurrent');
        delete axios.defaults.headers.common.Authorization;
        resolve();
      });
    },
  },
  mutations: {
    loginSuccess(state, loggedInUser) {
      state.status.loggedIn = true;
      state.user = loggedInUser;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, token) {
      state.status = 'success';
      state.token = token;
    },
    tc_check_success(state) {
      state.tcCurrent = true;
      localStorage.setItem('tcCurrent', true);
    },
    tc_check_failure(state) {
      state.tcCurrent = false;
      localStorage.setItem('tcCurrent', false);
    },
    auth_error(state) {
      state.status = 'error';
    },
  },
  getters: {
    isTermsAndConditionsCurrentVersion: (state) => {
      return state.tcCurrent || localStorage.getItem('tcCurrent') === true;
    },
    isLoggedIn: (state) => {
      // Check to see if we have a token
      let unexpired = false;
      let hasToken = false;

      if (state.token) {
        hasToken = true;
        const decoded = jwtDecode(state.token);

        const currentTime = Date.now() / 1000;

        // If token isn't expired, we're good to issue the login
        if (decoded.exp > currentTime) {
          unexpired = true;
        }
      }

      return hasToken && unexpired;
    },
    getClientId: (state) => {
      let client = null;

      if (state.token) {
        const decoded = jwtDecode(state.token);

        client = decoded.client;
      }

      return client;
    },
    authStatus: (state) => state.status,
  },
};

export default auth;
