<template>
  <v-container class="ma-0 pa-0">
    <v-dialog
      v-model="newMfgDialog"
      max-width="500px"
    >
      <new-manufacturer-form
        @manufacturerCreated="addMfg"
      />
    </v-dialog>
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="manufacturersLoading"
      :search-input.sync="search"
      hide-no-data
      :hint="!isEditing && locked ? 'Click the lock to edit' : ''"
      persistent-hint
      :label="label"
      placeholder="Start typing to search"
      item-text="description"
      item-value="Manufacturer"
      :readonly="!isEditing && locked"
      return-object
      offset-y
      @click="overwriteSearch = true"
    >
      <template v-slot:progress>
        <v-progress-linear
          v-show="searchPending"
          indeterminate
          rounded
          height="10"
        />
      </template>
      <template
        v-slot:append-outer
      >
        <v-slide-x-reverse-transition
          v-if="locked"
          mode="out-in"
        >
          <v-icon
            :key="`icon-${isEditing}`"
            :color="isEditing ? 'success' : 'danger'"
            :disabled="disableEditing"
            @click="isEditing = !isEditing"
            v-text="isEditing ? 'mdi-lock-open' : 'mdi-lock'"
          />
        </v-slide-x-reverse-transition>
        <v-icon
          v-if="allowNew"
          @click="newMfgDialog = true"
        >
          mdi-plus
        </v-icon>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

import NewManufacturerForm from './NewManufacturerForm.vue';

export default {
  name: 'ManufacturerSearch',
  components: {
    NewManufacturerForm,
  },
  props: {
    mfg: {
      type: Object,
      default: () => {},
    },
    title: {
      type: Boolean,
      default: () => true,
    },
    locked: {
      type: Boolean,
      default: () => false,
    },
    allowNew: {
      type: Boolean,
      default: () => true,
    },
    disableEditing: {
      type: Boolean,
      default: () => false,
    },
    labelText: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    entries: [],
    mfgsLoading: false,
    mfgTypes: [],
    newMfgDialog: false,
    manufacturersLoading: false,
    model: null,
    search: null,
    timerId: null,
    overwriteSearch: false,
    isEditing: false,
  }),
  computed: {
    fields() {
      if (!this.model) {
        return [];
      }

      return Object.keys(this.model).map((key) => ({
        key, value: this.model[key] || 'n/a',
      }));
    },
    items() {
      return this.entries.map((entry) => {
        const description = `${entry.name} (${entry.manufacturerType})`;
        return { ...entry, description };
      });
    },
    label() {
      if (this.labelText) {
        return this.labelText;
      }

      return 'Manufacturers';
    },
  },
  watch: {
    async search(val) {
      if (this.overwriteSearch) {
        await this.searchMfgsDebounced(val);
      }
    },
    model(val) {
      this.$emit('onClickMfg', val);
    },
  },
  mounted() {
    if (this.mfg) {
      this.entries.push(this.mfg);

      // eslint-disable-next-line prefer-destructuring
      this.model = this.items[0];
    }
  },
  methods: {
    ...mapActions('apiClient', ['get', 'post']),
    addMfg(mfg) {
      setTimeout(() => {
        this.newMfgDialog = false;
        this.setMfg(mfg);
      }, 3000);
    },
    setMfg(mfg) {
      this.overwriteSearch = false;
      this.entries.push(mfg);
      // eslint-disable-next-line prefer-destructuring
      this.model = this.items[0];
    },
    async searchMfgsDebounced(query) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.searchMfgs(query);
      }, 300);
    },
    async searchMfgs(query) {
      if (!query) {
        return;
      }

      this.mfgsLoading = true;

      await this.get({ url: '/manufacturers/autocomplete', params: { q: query } }).then(
        (response) => {
          this.entries = response.data;
        },
        (error) => {
          console.log(error);
        },
      );

      this.mfgsLoading = false;
    },
  },
};
</script>
