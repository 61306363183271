<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="blue darken-1"
          text
          ripple
          v-bind="attrs"
          v-on="on"
        >
          Show Search Options
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Inventory Search Options</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :return-value.sync="query.startDate"
                transition="scale-transition"
                max-width="290"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.startDate"
                    v-bind="attrs"
                    label="Start Date"
                    clearable
                    @click:clear="query.startDate = null"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="query.startDate"
                  width="290"
                  scrollable
                  @change="saveStartDate"
                />
              </v-menu>
            </v-row>
            <v-row>
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                :return-value.sync="query.endDate"
                transition="scale-transition"
                max-width="290"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.endDate"
                    v-bind="attrs"
                    label="End Date"
                    clearable
                    @click:clear="query.endDate = null"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="query.endDate"
                  scrollable
                  width="290"
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="saveEndDate"
                />
              </v-menu>
            </v-row>
            <v-row>
              <client-auto-complete
                :title="false"
                :subclient="true"
                :allow-new="false"
                :client="subClient"
                @onClickClient="subClientSelected"
              />
            </v-row>
            <v-row>
              <manufacturer-auto-complete
                :title="false"
                :allow-new="false"
                @onClickMfg="mfgSelected"
              />
            </v-row>
            <v-row>
              <v-select
                v-model="query.billingStatus"
                :items="billingOptions"
                item-text="title"
                item-value="value"
                :return-object="false"
                label="Billing Status"
              />
            </v-row>
            <v-row>
              <v-select
                v-model="query.activeStatus"
                :items="activeOptions"
                item-text="title"
                item-value="value"
                :return-object="false"
                label="Active Status"
              />
            </v-row>
            <v-row
              align="center"
              justify="space-around"
            >
              <v-btn
                text
                ripple
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                ripple
                @click="applyFilters"
              >
                Apply
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import ClientAutoComplete from './ClientAutoComplete.vue';
import ManufacturerAutoComplete from './ManufacturerAutoComplete.vue';

export default {
  name: 'InventorySearchFilter',
  components: {
    ClientAutoComplete,
    ManufacturerAutoComplete,
  },
  props: {
    start: {
      type: String,
      default: null,
    },
    end: {
      type: String,
      default: null,
    },
    billingStatus: {
      type: Boolean,
      default: null,
    },
    locationStatus: {
      type: String,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    subClient: {
      type: Object,
      default: null,
    },
    activeStatus: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    startDateMenu: false,
    endDateMenu: false,
    query: {
      startDate: null,
      endDate: null,
      clientId: null,
      subClientId: null,
      mfgId: null,
      billingStatus: null,
      locationStatus: null,
      activeStatus: null,
    },
    billingOptions: [
      { title: 'Billed', value: true },
      { title: 'Unbilled', value: false },
      { title: 'All', value: null },
    ],
    activeOptions: [
      { title: 'Active', value: true },
      { title: 'Inactive', value: false },
      { title: 'All', value: null },
    ],
    locationOptions: [
      'In Warehouse',
      'Picked Up From Loading Dock',
      'Delivered',
    ],
  }),
  created() {
    if (this.start) {
      this.query.startDate = this.start;
    }

    if (this.end) {
      this.query.endDate = this.end;
    }

    if (this.billingStatus !== null && this.billingStatus !== undefined) {
      this.query.billingStatus = this.billingStatus;
    }

    if (this.activeStatus !== null && this.activeStatus !== undefined) {
      this.query.activeStatus = this.activeStatus;
    }

    if (this.locationStatus) {
      this.query.locationStatus = this.locationStatus;
    }
  },
  methods: {
    ...mapGetters('auth', ['getClientId']),
    clientSelected(client) {
      this.query.clientId = client.clientId;
    },
    subClientSelected(subClient) {
      this.query.subClientId = subClient.clientId;
    },
    mfgSelected(mfg) {
      this.query.mfgId = mfg.manufacturerId;
    },
    saveStartDate(date) {
      this.$refs.startDateMenu.save(date);
    },
    saveEndDate(date) {
      this.$refs.endDateMenu.save(date);
    },
    applyFilters() {
      this.query.clientId = this.getClientId();
      this.$emit('filtersSelected', this.query);
      this.dialog = false;
    },
  },
};
</script>
