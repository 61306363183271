<template>
  <v-container fluid>
    <inventory-search-filter
      v-if="!mostRecent"
      :client="client"
      :sub-client="subClient"
      @filtersSelected="doSearch"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="searchPending"
      :page="page"
      :items-per-page="-1"
      loading-text="loading, please wait"
      class="elevation-1"
      @click:row="editItem"
    >
      <template v-slot:progress>
        <v-progress-linear
          v-show="searchPending"
          indeterminate
          rounded
          height="10"
        />
      </template>
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Filter table"
          class="mx-4"
        />
      </template>
      <template v-slot:item.receivedDate="{ item }">
        {{ item.receivedDate.substr(0, 10) }}
      </template>
      <template v-slot:item.billed="{ item }">
        <v-simple-checkbox
          :value="item.active"
          disabled
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import InventorySearchFilter from './InventorySearchFilter.vue';

export default {
  name: 'InventoryList',
  components: {
    InventorySearchFilter,
  },
  props: {
    clientId: {
      type: String,
      default: null,
    },
    subClientId: {
      type: String,
      default: null,
    },
    pairClientId: {
      type: String,
      default: null,
    },
    pairSubClientId: {
      type: String,
      default: null,
    },
    mostRecent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    page: 1,
    numberOfPages: 0,
    client: null,
    subClient: null,
    totalItems: 0,
    options: {},
    query: {
      startDate: null,
      endDate: null,
      clientId: null,
      subClientId: null,
      mfgId: null,
      billingStatus: null,
      locationStatus: null,
      pairClientId: null,
      pairSubClientId: null,
      activeStatus: null,
    },
    searchPending: false,
    headers: [
      {
        text: 'ICN',
        value: 'icn',
      },
      {
        text: 'Received',
        value: 'receivedDate',
      },
      {
        text: 'Description',
        value: 'description',
        sortable: false,
      },
      {
        text: 'Manufacturer',
        value: 'manufacturer.name',
      },
      {
        text: 'Client',
        value: 'client.name',
      },
      {
        text: 'SubClient',
        value: 'subClient.name',
      },
      {
        text: 'Photos & Files',
        value: 'assets.length',
      },
      {
        text: 'Active',
        value: 'billed',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],
    items: [],
    startDate: '',
    endDate: '',
  }),
  watch: {
    startDateMenu() {
      this.searchByDate();
    },
    endDateMenu() {
      this.searchByDate();
    },
    options: {
      handler() {
        this.searchInventory();
      },
    },
    deep: true,
  },
  created() {
    this.loading = true;
    // this.query.locationStatus = 'In Warehouse';
    // this.query.billingStatus = false;
    this.query.activeStatus = true;

    const clientId = this.getClientId();
    this.query.clientId = clientId;
    this.getClient(clientId);

    if (this.subClientId) {
      this.query.subClientId = this.subClientId;
      this.getSubClient(this.subClientId);
    }

    if (this.pairClientId && this.pairSubClientId) {
      this.query.pairClientId = this.pairClientId;
      this.query.pairSubClientId = this.pairSubClientId;

      this.getClient(this.pairClientId);
      this.getSubClient(this.pairSubClientId);
    }

    this.searchInventory();
  },
  methods: {
    ...mapActions('apiClient', ['get', 'post']),
    ...mapGetters('auth', ['getClientId']),
    editItem(item) {
      const route = this.$router.resolve({ name: 'inventoryEdit', params: { itemId: item.itemId } });
      window.open(route.href, '_blank');
    },
    async doSearch(query) {
      this.query = query;
      await this.searchInventory();
    },
    async getClient(clientId) {
      await this.get({ url: `/clients/${clientId}` }).then(
        (response) => {
          this.client = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    async getSubClient(clientId) {
      await this.get({ url: `/clients/${clientId}` }).then(
        (response) => {
          this.subClient = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    async searchInventory() {
      this.searchPending = true;

      const url = this.mostRecent ? `/inventory/recent/${this.query.clientId}` : '/inventory/search?sortOverride=false';

      await this.post({ url, body: this.query }).then(
        (response) => {
          this.items = response.data.data;
          this.totalItems = response.data.totalElements;
          this.numberOfPages = response.data.totalPages;
        },
        (error) => {
          console.log(error);
        },
      );

      this.searchPending = false;
    },
  },
};
</script>
