<template>
  <v-container class="ma-0 pa-0">
    <v-dialog
      v-model="newClientDialog"
      max-width="500px"
    >
      <new-client-form
        @clientCreated="addClient"
      />
    </v-dialog>
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="clientsLoading"
      :search-input.sync="search"
      hide-no-data
      :label="label"
      placeholder="Start typing to search"
      item-text="description"
      item-value="Client"
      return-object
      persistent-hint
      :readonly="!isEditing && locked"
      offset-y
      @click="overwriteSearch = true"
    >
      <template v-slot:progress>
        <v-progress-linear
          v-show="clientsLoading"
          indeterminate
          rounded
          height="10"
        />
      </template>
      <template
        v-slot:append-outer
      >
        <v-slide-x-reverse-transition
          v-if="locked"
          mode="out-in"
        >
          <v-icon
            :key="`icon-${isEditing}`"
            :disabled="disableEditing"
            :color="isEditing ? 'success' : 'danger'"
            @click="enableEditing"
            v-text="isEditing ? 'mdi-lock-open' : 'mdi-lock'"
          />
        </v-slide-x-reverse-transition>
        <v-icon
          v-if="allowNew"
          @click="newClientDialog = true"
        >
          mdi-plus
        </v-icon>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import NewClientForm from './NewClientForm.vue';

export default {
  name: 'ClientSearch',
  components: {
    NewClientForm,
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    locked: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: Boolean,
      default: () => true,
    },
    subclient: {
      type: Boolean,
      default: () => false,
    },
    allowNew: {
      type: Boolean,
      default: () => true,
    },
    disableEditing: {
      type: Boolean,
      default: () => false,
    },
    labelText: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    entries: [],
    clientsLoading: false,
    model: null,
    isEditing: false,
    search: null,
    timerId: null,
    overwriteSearch: false,
    newClientDialog: false,
    clientId: null,
  }),
  computed: {
    label() {
      if (this.labelText) {
        return this.labelText;
      }
      if (this.subclient) {
        return 'Sub Clients';
      }
      return 'Clients';
    },
    fields() {
      if (!this.model) {
        return [];
      }

      return Object.keys(this.model).map((key) => ({
        key,
        value: this.model[key] || 'n/a',
      }));
    },
    items() {
      return this.entries.map((entry) => {
        let description = entry.name;

        if (entry.icnAbbreviation) {
          description = `${description} (${entry.icnAbbreviation})`;
        }
        
        return { ...entry, description };
      });
    },
  },
  watch: {
    async search(val) {
      if (this.overwriteSearch) {
        await this.searchClientsDebounced(val);
      }
    },
    model(val) {
      this.$emit('onClickClient', val);
    },
  },
  created() {
    this.clientId = this.getClientId();
  },
  mounted() {
    if (this.client) {
      this.entries.push(this.client);
      // eslint-disable-next-line prefer-destructuring
      this.model = this.items[0];
    }
  },
  methods: {
    ...mapActions('apiClient', ['get']),
    ...mapGetters('auth', ['getClientId']),
    addClient(client) {
      setTimeout(() => {
        this.newClientDialog = false;
        this.setClient(client);
      }, 3000);
    },
    async searchClientsDebounced(query) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.searchClients(query);
      }, 300);
    },
    setClient(client) {
      this.overwriteSearch = false;
      this.entries.push(client);
      // eslint-disable-next-line prefer-destructuring
      this.model = this.items[0];
    },
    enableEditing() {
      this.isEditing = !this.isEditing;

      if (this.isEditing && this.locked) {
        this.$emit('editingEnabled');
      }
    },
    async searchClients(query) {
      if (!query) {
        return;
      }

      this.clientsLoading = true;

      await this.get({ url: `/clients/autocomplete/${this.clientId}`, params: { q: query } }).then(
        (response) => {
          this.entries = response.data;
        },
        (error) => {
          console.log(error);
        },
      );

      this.clientsLoading = false;
    },
  },
};
</script>
