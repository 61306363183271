<template>
  <v-card>
    <v-card-title primary-title style="flex-direction: column;">
      <div class="headline">
        Create a New Project
      </div>
      <div>
        <v-form>
          <v-text-field
            v-model="project.projectName"
            :error-messages="projectNameErrors"
            label="Project Name"
            @input="$v.project.projectName.$touch()"
            @blur="$v.project.projectName.$touch()"
          />
          <client-auto-complete
            :subclient="true"
            labelText="Subclient"
            @onClickClient="subClientSelected"
          />
          <v-btn color="primary" @click="onClickCreate">
            Create
          </v-btn>
        </v-form>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import ClientAutoComplete from "../ClientAutoComplete.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "NewCostEstimatorProject",
  components: {
    ClientAutoComplete,
  },
  data: () => ({
    project: {
      projectName: null,
      subClient: {},
    },
  }),
  computed: {
    projectNameErrors() {
      const errors = [];

      if (!this.$v.project.projectName.$dirty) {
        return errors;
      }

      if (!this.$v.project.projectName) {
        errors.push("Please enter a name for this project");
      }

      return errors;
    },
  },
  validations: {
    project: {
      projectName: {
        required,
      },
    },
  },
  methods: {
    subClientSelected(subClient) {
      this.project.subClient = subClient;
    },
    onClickCreate() {
      this.$emit("onProjectCreate", this.project);

      this.project = {
        projectName: null,
        subClient: {},
      };
    },
  },
};
</script>
