<template>
  <v-card>
    <success-alert
      v-if="successfullyCreated"
      :name="client.name"
    />
    <v-card-title
      primary-title
      style="flex-direction: column;"
    >
      <div class="headline">
        Create Client
      </div>
      <div>
        <v-form
          v-model="valid"
          @submit.prevent="onCreateClient"
          @keydown.prevent.enter
        >
          <v-text-field
            v-model="client.name"
            :error-messages="nameErrors"
            label="Name"
            required
            @input="$v.client.name.$touch()"
            @blur="$v.client.name.$touch()"
          />
          <phone-component
            :input="client.phone"
            :required="false"
            @phoneSet="setPhone"
          />
          <v-text-field
            v-model="client.email"
            :error-messages="emailErrors"
            label="Email"
            required
            @input="$v.client.email.$touch()"
            @blur="$v.client.email.$touch()"
          />
          <v-text-field
            v-model="client.address1"
            :error-messages="address1Errors"
            label="Address 1"
            required
            @input="$v.client.address1.$touch()"
            @blur="$v.client.address1.$touch()"
          />
          <v-text-field
            v-model="client.address2"
            label="Address 2"
          />
          <v-text-field
            v-model="client.city"
            :error-messages="cityErrors"
            label="City"
            required
            @input="$v.client.city.$touch()"
            @blur="$v.client.city.$touch()"
          />
          <state-select @stateSelected="setState" />
          <v-text-field
            v-model="client.zipCode"
            :error-messages="zipCodeErrors"
            label="Zip Code"
            required
            @input="$v.client.zipCode.$touch()"
            @blur="$v.client.zipCode.$touch()"
          />
          <v-btn
            type="submit"
            color="primary darken-1"
            class="text-center"
            :disabled="!valid"
            :loading="submissionPending"
          >
            Create
          </v-btn>
        </v-form>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import {
  required, email, minLength, maxLength, numeric,
} from 'vuelidate/lib/validators';
import StateSelect from './StateSelectComponent.vue';
import PhoneComponent from './PhoneComponent.vue';
import SuccessAlert from './SuccessAlert.vue';

export default {
  name: 'NewClientForm',
  components: {
    StateSelect,
    PhoneComponent,
    SuccessAlert,
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({
        empty: true,
      }),
    },
    autoDismiss: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    dialog: false,
    fullPage: true,
    valid: false,
    client: {},
    submissionPending: false,
    successfullyCreated: false,
  }),
  computed: {
    ...mapGetters({
      isLoading: 'apiClient/showLoading',
      hasError: 'apiClient/showError',
    }),
    nameErrors() {
      const errors = [];

      if (!this.$v.client.name.$dirty) {
        return errors;
      }

      if (!this.$v.client.name.required) {
        errors.push('Name is required');
      }

      if (!this.$v.client.name.maxLength) {
        errors.push('Name cannot be greater than 50 characters');
      }

      if (!this.$v.client.name.isUnique) {
        errors.push('A client already exists with this name');
      }

      return errors;
    },
    phoneErrors() {
      const errors = [];

      if (!this.$v.client.phone.$dirty) {
        return errors;
      }

      if (!this.$v.client.phone.required) {
        errors.push('This field is required');
      }

      if (!this.$v.client.phone.numeric) {
        errors.push('This field can only contain numbers');
      }

      if (!this.$v.client.phone.minLength || !this.$v.client.phone.maxLength) {
        errors.push('This field must be exactly 10 digits long');
      }

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.client.email.$dirty) {
        return errors;
      }

      if (!this.$v.client.email.required) {
        errors.push('Email is required');
      }

      if (!this.$v.client.email.email) {
        errors.push('Please enter a valid email address');
      }

      return errors;
    },
    address1Errors() {
      const errors = [];

      if (!this.$v.client.address1.$dirty) {
        return errors;
      }

      if (!this.$v.client.address1.required) {
        errors.push('This field is required');
      }

      return errors;
    },
    cityErrors() {
      const errors = [];

      if (!this.$v.client.city.$dirty) {
        return errors;
      }

      if (!this.$v.client.city.required) {
        errors.push('This field is required');
      }

      return errors;
    },
    zipCodeErrors() {
      const errors = [];

      if (!this.$v.client.zipCode.$dirty) {
        return errors;
      }

      if (!this.$v.client.zipCode.required) {
        errors.push('This field is required');
      }

      if (!this.$v.client.zipCode.numeric) {
        errors.push('This field can only contain numeric characters');
      }

      if (!this.$v.client.zipCode.maxLength) {
        errors.push('Max 5 characters');
      }

      return errors;
    },
  },
  validations: {
    client: {
      name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(1),
        async isUnique(value) {
          if (value === '') {
            return true;
          }
          const unique = await this.validateName();
          return Boolean(unique);
        },
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      email: {
        required,
        email,
      },
      address1: {
        required,
      },
      city: {
        required,
      },
      zipCode: {
        required,
        numeric,
        maxLength: maxLength(5),
      },
    },
  },
  created() {
    if (!this.initialData.empty) {
      this.client = this.initialData;
    }
  },
  methods: {
    ...mapActions('apiClient', ['create', 'get']),
    ...mapMutations('apiClient', ['dismissError']),
    setState(val) {
      this.client.state = val;
    },
    setIcn(val) {
      this.client.icnAbbreviation = val;
    },
    setPhone(val) {
      this.client.phone = val;
    },
    async validateName() {
      if (!this.client.name || this.client.name < 1) {
        return true;
      }

      let valid = true;

      await this.get({ url: '/clients/checkName', params: { name: this.client.name } }).then(
        (response) => {
          valid = response.data.valid;
        },
        (error) => {
          console.log(error);
          valid = false;
        },
      );

      return valid;
    },
    async onCreateClient() {
      this.submissionPending = true;

      await this.create({ url: '/clients', body: this.client }).then(
        (response) => {
          this.$emit('clientCreated', response.data);
          this.successfullyCreated = true;
        },
        (error) => {
          console.log(error);
        },
      );

      this.submissionPending = false;
    },
  },
};
</script>
