<template>
  <v-text-field
    v-model="phone"
    :error-messages="phoneErrors"
    :label="label"
    :readonly="readOnly"
    :required="required"
    @keyup="emitPhone"
    @input="$v.phone.$touch()"
    @blur="$v.phone.$touch()"
  />
</template>

<script>
import {
  required, maxLength, minLength, numeric,
} from 'vuelidate/lib/validators';

export default {
  props: {
    input: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Phone',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    rawPhone: '',
  }),
  computed: {
    phone: {
      get() {
        return this.phoneFormat(this.rawPhone);
      },
      set(val) {
        this.rawPhone = val.replace(/[^0-9]/g, '');
      },
    },
    phoneErrors() {
      const errors = [];

      if (!this.$v.phone.$dirty) {
        return errors;
      }

      if (!this.$v.phone.maxLength || !this.$v.phone.minLength) {
        if (this.rawPhone.length !== 10) {
          errors.push('Phone number must be exactly 10 digits long');
        }
      }

      if (!this.$v.phone.numeric) {
        if (!/^[0-9]/.test(this.rawPhone)) {
          errors.push('Phone number can only contain numbers');
        }
      }

      if (!this.$v.phone.required && this.required) {
        errors.push('Phone number is required');
      }

      return errors;
    },
  },
  watch: {
    input() {
      if (this.input) {
        this.rawPhone = this.input;
      } else {
        this.rawPhone = '';
      }
    },
  },
  created() {
    if (this.input) {
      this.rawPhone = this.input;
    }
  },
  validations: {
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
      numeric,
    },
  },
  methods: {
    phoneFormat(val) {
      return val ? val.replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : val;
    },
    emitPhone() {
      if (this.rawPhone.length === 10) {
        this.$emit('phoneSet', this.rawPhone);
      }
    },
  },
};
</script>
