<template>
  <v-container fluid>
    <v-row class="ml-2">
      <v-col>
        <span>
          <h1>
            {{ client.name }} - {{ client.icnAbbreviation }}
          </h1>
        </span>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col class="col-md-10">
        <v-tabs class="elevation-10">
          <v-tab>
            Subclients
          </v-tab>
          <v-tab>
            Contacts
          </v-tab>
          <v-tab>
            All Inventory
          </v-tab>
          <v-tab>
            Pending Inventory
          </v-tab>
          <v-tab>
            Most Recent Inventory
          </v-tab>
        <v-tab-item>
            <v-card class="elevation-10">
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="subClients"
                  :items-per-page="-1"
                  :loading="subClientLoading"
                  :search="subClientSearch"
                  dense
                  loading-text="loading, please wait"
                  @click:row="goToInventory"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="subClientSearch"
                      prepend-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    />
                    <v-switch
                      v-model="includeInactive"
                      label="Include Subclients with no active inventory"
                      color="primary"
                    />
                  </template>
                  <template v-slot:item.totalToDate="{ item }">
                    <span>{{ item.totalToDate | currency }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="elevation-10">
              <client-contacts :client-id="clientId" />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="elevation-10">
              <inventory-list />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="elevation-10">
              <pending-inventory-list />
            </v-card>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <inventory-list :mostRecent="true" />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row align="stretch">
      <v-col class="col-md-10">
        <v-tabs class="elevation-10">
          <v-tab>
            Client Info
          </v-tab>
          <v-tab>
            Client Photo
          </v-tab>
          <v-tab>
            Email Recipients
          </v-tab>
          <v-tab-item>
            <v-card
              v-if="!loading"
              class="elevation-10"
            >
              <v-card-text>
                <v-text-field
                  v-model="client.name"
                  label="Name"
                  readonly
                />
                <phone-component
                  :input="client.phone"
                  :read-only="true"
                />
                <v-text-field
                  v-model="client.email"
                  label="Email"
                  readonly
                />
                <v-textarea
                  v-model="clientAddress"
                  label="Address"
                  readonly
                  rows="3"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <photo-component
              :owner-id="clientId"
              :edit-enabled="false"
              default-text="Upload a Client Photo"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card class="elevation-10">
              <v-data-table
                :headers="emailRecipientHeaders"
                :items="emailRecipients"
                :items-per-page="-1"
                :loading="recipientsLoading"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    @click.stop="deleteRecipient(item)"
                    @click.stop.prevent
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PhoneComponent from './PhoneComponent.vue';
import ClientContacts from './ClientContactList.vue';
import PhotoComponent from './PhotoComponent.vue';
import InventoryList from './InventoryList.vue';
import PendingInventoryList from './PendingInventoryList.vue';

export default {
  name: 'ClientProfileComponent',
  components: {
    PhoneComponent,
    ClientContacts,
    PhotoComponent,
    InventoryList,
    PendingInventoryList,
  },
  data: () => ({
    subClientLoading: false,
    includeInactive: false,
    client: {},
    clientId: null,
    isFullPage: true,
    editing: false,
    loading: false,
    successDialog: false,
    errorDialog: false,
    subClients: [],
    subClientSearch: '',
    recipientsLoading: false,
    emailRecipients: [],
    emailRecipientHeaders: [
      {
        text: 'Email Address',
        value: 'emailAddress',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    headers: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'ICN Abbreviation',
        value: 'icnAbbreviation',
      },
      {
        text: 'Pieces',
        value: 'itemCount',
        filterable: false,
      },
      {
        text: 'Storage Total to Date (does not include any quarterly storage payments made)',
        value: 'totalToDate',
        filterable: false,
      },
    ],
  }),
  watch: { 
    includeInactive(val) { 
      this.getSubClientPairs(val);
    },
  },
  mounted() {
    this.client = this.getClient();
    this.getSubClientPairs(this.includeInactive);
    this.getEmailRecipients();
  },
  created() {
    this.clientId = this.getClientId();
  },
  computed: {
    clientAddress() {
      let text = '';

      if (this.client.address1) {
        text += this.client.address1;
      }

      if (this.client.address2) {
        text += '\n';
        text += this.client.address2;
      }

      if (this.client.city) {
        text += '\n';
        text += this.client.city;
      }

      if (this.client.state) {
        text += `, ${this.client.state}`;
      }

      if (this.client.zipCode) {
        text += ` ${this.client.zipCode}`;
      }

      return text;
    },
  },
  methods: {
    ...mapActions('apiClient', ['get', 'update', 'del', 'create']),
    ...mapGetters('auth', ['getClientId']),
    goToClientInventory() {
      this.$router.push({ name: 'inventoryList', params: { clientId: this.client.clientId, subClientId: this.client.clientId } });
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    async getClient() {
      this.loading = true;

      await this.get({ url: '/clients', id: this.clientId }).then(
        (response) => {
          this.client = response.data;
        },
        (error) => {
          console.log(error);
        },
      );

      this.loading = false;
    },
    goToInventory(props) {
      this.$router.push({ name: 'inventoryList', params: { pairClientId: this.client.clientId, pairSubClientId: props.subClientId } });
    },
    async getSubClientPairs(includeInactive) {
      this.subClientLoading = true;
      await this.get({ url: `/clients/${this.clientId}/subClients?client=true&includeInactiveInventory=${includeInactive}` }).then(
        (response) => {
          this.subClients = response.data;
          this.subClientLoading = false;
        },
        (error) => {
          console.log(error);
          this.subClientLoading = false;
        },
      );
    },
    async getEmailRecipients() {
      this.recipientsLoading = true;
      await this.get({ url: `clients/${this.clientId}/emailRecipients` }).then(
        (response) => {
          this.emailRecipients = response.data;
          this.recipientsLoading = false;
        },
        (error) => {
          this.recipientsLoading = false;
          console.log(error);
        },
      );
    },
    deleteRecipient(recipient) {
      this.del({ url: `/clients/emailRecipients/${recipient.id}` }).then(
        (response) => {
          if (response.data.successful) {
            this.getEmailRecipients();
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>
