import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import LoginComponent from '../components/LoginComponent.vue';
import NewInventoryItemForm from '../components/NewInventoryForm.vue';
import InventoryList from '../components/InventoryList.vue';
import InventorySummaryForm from '../components/InventorySummaryForm.vue';
import RegisterComponent from '../components/RegisterComponent.vue';
import ClientProfilecomponent from '../components/ClientProfileComponent.vue';
import PendingInventorySummaryForm from '../components/PendingInventorySummaryForm.vue';
import ChargeComponent from '../components/ChargesComponent.vue';
import ChangePasswordComponent from '../components/ChangePasswordComponent.vue';
import TermsAndConditionsComponent from '../components/TermsAndConditionsComponent.vue';
import CostEstimatorProjects from '../components/costEstimator/CostEstimatorProjects.vue';
import CostEstimatorProjectItems from '../components/costEstimator/CostEstimatorProjectItems.vue';
import ForgotPasswordComponent from '../components/ForgotPasswordComponent.vue';
import ResetForgottenPasswordComponent from '../components/ResetForgottenPasswordComponent.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginComponent,
    meta: {
      layout: 'simple',
    },
    props: true,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterComponent,
    meta: {
      layout: 'simple',
    },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ForgotPasswordComponent,
    meta: {
      layout: 'simple',
    },
  },
  {
    path: '/passwordReset',
    name: 'passwordReset',
    component: ResetForgottenPasswordComponent,
    meta: {
      layout: 'simple',
    },
  },
  {
    path: '/inventory',
    name: 'inventoryList',
    component: InventoryList,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/client',
    name: 'clientProfile',
    component: ClientProfilecomponent,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/inventory/new',
    name: 'newInventory',
    component: NewInventoryItemForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/inventory/:itemId',
    name: 'inventoryEdit',
    component: InventorySummaryForm,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/inventory/pending/:itemId',
    name: 'pendingInventoryEdit',
    component: PendingInventorySummaryForm,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/',
    name: 'home',
    component: ClientProfilecomponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/charges',
    name: 'charges',
    component: ChargeComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/estimator',
    name: 'estimator',
    component: CostEstimatorProjects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/estimator/:projectId',
    name: 'estimatorProjectView',
    component: CostEstimatorProjectItems,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: ChangePasswordComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/termsandconditions',
    name: 'termsAndConditions',
    component: TermsAndConditionsComponent,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const loggedIn = store.getters['auth/isLoggedIn'];
    const acceptedLatestTerms = store.getters['auth/isTermsAndConditionsCurrentVersion'];
    if (loggedIn && acceptedLatestTerms) {
      next();
      return;
    } else if (!loggedIn) {
      next('/login');
    } else if (!acceptedLatestTerms) {
      next('/termsandconditions');
    } else {
      next('/login')
    }
  } else {
    next();
  }
});

export default router
