<template>
  <v-container fluid>
    <v-alert
      v-model="duplicatesCreatedAlert"
      dismissible
      prominent
      type="success"
      transition="scale-transition"
    >
      {{ duplicatesCreated }} identical pending items created
    </v-alert>
    <v-dialog
      v-model="createDuplicatesDialog"
      max-width="300px"
      max-height="100px"
    >
      <v-card>
        <v-card-title>
          How many identical items should be created?
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="duplicateCount"
            :items="duplicateCountOptions"
            label="Quantity"
          />
          <v-btn
            text
            @click="createDuplicatesDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            :disabled="duplicateCount === 0"
            :loading="creatingDuplicates"
            @click="createDuplicates"
          >
            Create {{ duplicateCount }} duplicates
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-stepper
      v-model="currentStep"
      vertical
    >
      <v-stepper-step
        :complete="currentStep > 1"
        step="1"
      >
        <div v-html="dateStepName" />
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-menu
          ref="menu"
          v-model="menu"
          max-width="290"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="slide-x-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            width="290"
            scrollable
            :min="new Date().toISOString().substr(0, 10)"
          >
            <v-spacer />
            <v-btn
              color="primary"
              @click="$refs.menu.save(date)"
            >
              OK
            </v-btn>
            <v-btn
              text
              @click="menu = false"
            >
              Cancel
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-btn
          color="primary"
          text
          @click="nextStep"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="currentStep > 2"
        step="2"
      >
        <div v-html="subClientStepName" />
      </v-stepper-step>

      <v-stepper-content step="2">
        <client-auto-complete
          :title="false"
          :subclient="true"
          @onClickClient="subClientSelected"
        />
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="isSubClientSelected"
          @click="nextStep"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="3"
        :complete="currentStep > 3"
      >
        <div v-html="manufacturerStepName" />
      </v-stepper-step>
      <v-stepper-content step="3">
        <manufacturer-auto-complete
          :key="rerenderMfgAutocomplete"
          :title="false"
          @onClickMfg="mfgSelected"
        />
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="isMfgSelected"
          @click="nextStep"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="4"
        :complete="currentStep > 4"
      >
        <div v-html="descriptionStepName" />
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-textarea
          v-model="desc"
          outlined
          counter
          clearable
          label="Item Description"
          clear-icon="mdi-close"
          :rules="[rules.required, rules.lengthSixtyFour]"
          no-resize
        />
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="hasDescription"
          @click="nextStep"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="5"
        :complete="currentStep> 5"
      >
        <div v-html="sizeStepName" />
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-container>
          <v-row>
            <v-text-field
              v-model="width"
              type="number"
              label="Width (in inches)"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="length"
              type="number"
              label="Length (in inches)"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="height"
              type="number"
              label="Height (in inches)"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="cubicFeet"
              label="Size in cubic feet"
              readonly
              suffix=""
            />
          </v-row>
        </v-container>
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="nextStep"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="6"
        :complete="currentStep > 6"
      >
        <div v-html="generalNotesStepName" />
      </v-stepper-step>
      <v-stepper-content step="6">
        <v-container>
          <v-row v-if="!addGeneralNotes">
            Would you like to add any general notes?
          </v-row>
          <v-row v-if="!addGeneralNotes">
            <v-btn
              align="left"
              text
              ripple
              color="blue darken-1"
              @click="addGeneralNotes = true"
            >
              Yes
            </v-btn>
            <v-btn
              align="right"
              text
              ripple
              color="blue darken-1"
              @click="currentStep = 7"
            >
              No
            </v-btn>
          </v-row>
          <v-row v-if="addGeneralNotes">
            <v-textarea
              v-model="generalNotes"
              outlined
              counter
              clearable
              label="General Notes"
              clear-icon="mdi-close"
              no-resize
              :rules="[rules.lengthTwoFifty]"
            />
          </v-row>
        </v-container>
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          v-if="generalNotes"
          color="primary"
          text
          @click="nextStep"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="7"
        :complete="currentStep > 7"
      >
        Photos
      </v-stepper-step>
      <v-stepper-content step="7">
        <v-file-input
          v-model="firstFile"
          chips
          show-size
          accept="image/*"
          placeholder="First photo"
          prepend-icon="mdi-camera"
        />
        <v-file-input
          v-model="secondFile"
          chips
          show-size
          accept="image/*"
          placeholder="Second photo"
          prepend-icon="mdi-camera"
        />
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="reviewItem"
        >
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        step="8"
        :complete="currentStep > 8"
      >
        Review
      </v-stepper-step>
      <v-stepper-content step="8">
        <v-btn
          text
          @click="previousStep"
        >
          Back
        </v-btn>
        <v-btn
          :disabled="currentStep > 8"
          color="primary"
          @click="createItem"
        >
          Create
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    <v-dialog
      v-model="createItemDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          primary-title
          style="flex-direction: column;"
        >
          <!-- Item <a v-bind:href="'/inventory/' + item.itemId">{{ item.icn }}</a> has been created -->
          This item has been successfully created
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="firstFile !== null"
            align-content="center"
            justify="center"
          >
            <v-col
              class="subtitle-1 text-center"
              cols="12"
            >
              Uploading first photo
            </v-col>
            <v-col
              cols="6"
              class="subtitle-1 text-center"
            >
              <v-progress-linear
                v-if="uploadingPhoto1 && !upload1Complete"
                indeterminate
                color="primary"
              />
              <v-icon
                v-if="upload1Complete"
                center
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </v-col>
          </v-row>
          <v-row
            v-if="secondFile !== null"
            align-content="center"
            justify="center"
          >
            <v-col
              class="subtitle-1 text-center"
              cols="12"
            >
              Uploading second photo
            </v-col>
            <v-col
              cols="6"
              class="subtitle-1 text-center"
            >
              <v-progress-linear
                v-if="uploadingPhoto2 && !upload2Complete"
                indeterminate
                color="primary"
              />
              <v-icon
                v-if="upload2Complete"
                center
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="(upload1Complete || firstFile === null) && (secondFile === null || upload2Complete)"
          class="justify-center"
        >
          <v-menu
            top
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Actions
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="newItem"
              >
                <v-list-item-title>Create a new item</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item
                @click="showCreateDuplicates"
              >
                <v-list-item-title>
                  Create identical pending inventory
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClientAutoComplete from './ClientAutoComplete.vue';
import ManufacturerAutoComplete from './ManufacturerAutoComplete.vue';

export default {
  name: 'NewInventoryItemForm',
  components: {
    ClientAutoComplete,
    ManufacturerAutoComplete,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required',
      lengthSixtyFour: (value) => {
        if (value !== null && value !== undefined && value.length > 64) {
          return 'Max 64 characters';
        }

        return true;
      },
      lengthTwentyTwo: (value) => {
        if (value !== null && value !== undefined && value.length > 22) {
          return 'Max 22 characters';
        }

        return true;
      },
      lengthThirty: (value) => {
        if (value !== null && value !== undefined && value.length > 30) {
          return 'Max 30 characters';
        }

        return true;
      },
      lengthTwoFifty: (value) => {
        if (value !== null && value !== undefined && value.length > 250) {
          return 'Max 250 characters';
        }

        return true;
      },
    },
    firstFile: null,
    secondFile: null,
    invItem: null,
    client: {},
    subClient: {},
    mfg: {},
    date: new Date().toISOString().substr(0, 10),
    currentStep: 1,
    menu: false,
    rerenderMfgAutocomplete: 0,
    length: 1.0,
    height: 1.0,
    width: 1.0,
    desc: null,
    addlServices: false,
    addGeneralNotes: false,
    generalNotes: '',
    selectedServices: null,
    additionalServices: [
      { title: '1/4 Hour ($24.75)', value: 24.75 },
      { title: '1/2 Hour ($49.50)', value: 49.50 },
      { title: '3/4 Hour ($74.25)', value: 74.25 },
      { title: '1 Hour ($99.00)', value: 99.00 },
    ],
    selectedMaterials: 0,
    additionalMaterials: [5.00, 9.00, 14.00, 19.00, 24.00],
    sizeOptions: [],
    addlServicesNotes: '',
    addlMaterialsNotes: '',
    addlMats: false,
    showUploading: false,
    uploadingPhoto1: false,
    upload1Complete: false,
    uploadingPhoto2: false,
    upload2Complete: false,
    createItemDialog: false,
    item: {},
    createDuplicatesDialog: false,
    duplicateCountOptions: [],
    duplicateCount: 0,
    duplicatesCreated: 0,
    creatingDuplicates: false,
    duplicatesCreatedAlert: false,
  }),
  computed: {
    isClientSelected() {
      return this.client.clientId === undefined;
    },
    isSubClientSelected() {
      return this.subClient.clientId === undefined;
    },
    isMfgSelected() {
      return this.mfg.manufacturerId === undefined;
    },
    hasDescription() {
      return this.desc === null || this.desc === undefined
        || this.desc.length > 64 || this.desc.length === 0;
    },
    reviewDisabled() {
      return this.currentStep > 10;
    },
    cubicFeet() {
      const exactCubicFeet = (this.length / 12)
      * (this.width / 12)
      * (this.height / 12);
      const roundedCubicFeet = (Math.round(exactCubicFeet * 4) / 4).toFixed(2);
      return roundedCubicFeet;
    },
    dateStepName() {
      let text = '<span>Estimated Arrival Date</span>';

      if (this.date && this.currentStep > 1) {
        text += ` <span style="color: blue;"><strong>(${this.date})</strong></span>`;
      }

      return text;
    },
    subClientStepName() {
      let text = '<span>Select a Sub-Client</span>';

      if (this.subClient && this.subClient.name && this.currentStep > 2) {
        text += ` <span style="color: blue;"><strong>(${this.subClient.name})</strong></span>`;
      }

      return text;
    },
    manufacturerStepName() {
      let text = '<span>Select a Manufacturer</span>';

      if (this.mfg && this.mfg.name && this.currentStep > 3) {
        text += ` <span style="color: blue;"><strong>(${this.mfg.name})</strong></span>`;
      }

      return text;
    },
    descriptionStepName() {
      let text = '<span>Description</span>';

      if (this.desc && this.currentStep > 4) {
        text += ` <span style="color: blue;"><strong>(${this.desc})</strong></span>`;
      }

      return text;
    },
    sizeStepName() {
      let text = '<span>Estimated Size</span>';

      if (this.width && this.length && this.height && this.currentStep > 5) {
        text += ` <span style="color: blue;"><strong>(${this.width} x ${this.length} x ${this.height})</strong></span>`;
      }

      return text;
    },
    generalNotesStepName() {
      let text = '<span>General Notes</span>';

      if (this.generalNotes && this.currentStep > 6) {
        text += ` <span style="color: blue;"><strong>(${this.truncate(this.generalNotes, 15)})</strong></span>`;
      }

      return text;
    },
  },
  mounted() {
    for (let i = 1.0; i <= 16; i += 0.25) {
      this.sizeOptions.push(i);
    }

    for (let i = 1; i <= 100; i += 1) {
      this.duplicateCountOptions.push(i);
    }
  },
  created() {
    this.getClient(this.getClientId());
  },
  methods: {
    ...mapActions('apiClient', ['create', 'get']),
    ...mapGetters('auth', ['getClientId']),
    nextStep() {
      this.currentStep += 1;
    },
    async getClient(clientId) {
      await this.get({ url: `/clients/${clientId}` }).then(
        (response) => {
          this.client = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    previousStep() {
      this.currentStep -= 1;
    },
    clientSelected(client) {
      this.client = client;
    },
    subClientSelected(subClient) {
      this.subClient = subClient;
    },
    mfgSelected(mfg) {
      this.mfg = mfg;
    },
    reviewItem() {
      this.invItem = {
        client: this.client,
        subClient: this.subClient,
        manufacturer: this.mfg,
        description: this.desc,
        length: this.length,
        width: this.width,
        height: this.height,
        additionalMaterials: this.selectedMaterials,
        additionalServices: this.selectedServices,
        additionalMaterialsNotes: this.addlMaterialsNotes,
        additionalServicesNotes: this.addlServicesNotes,
        receivedDate: new Date(this.date),
        generalNotes: this.generalNotes,
      };

      this.nextStep();
    },
    async uploadFile(file) {
      this.msg = 'Uploading images';

      const formData = new FormData();
      formData.append('uploadFile', file, file.name);

      await this.create({ url: `/assets/${this.item.itemId}`, body: formData }).then(
        () => {
          this.uploaded += 1;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    showCreateDuplicates() {
      this.createDuplicatesDialog = true;
    },
    async createDuplicates() {
      this.creatingDuplicates = true;

      const duplicate = JSON.parse(JSON.stringify(this.item));
      duplicate.itemId = null;
      duplicate.icn = null;

      for (let i = 0; i < this.duplicateCount; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.create({ url: '/inventory/pending', body: duplicate }).then(
          () => {
            this.duplicatesCreated += 1;
          }
        )
      }

      this.creatingDuplicates = false;
      this.createDuplicatesDialog = false;
      this.duplicatesCreatedAlert = true;
      this.createItemDialog = false;
    },
    async createItem() {
      this.createItemDialog = true;

      await this.create({ url: '/inventory/pending', body: this.invItem }).then(
        (response) => {
          this.item = response.data;
          this.createItemDialog = true;
        },
        (error) => {
          console.log(error);
        },
      );

      if (this.firstFile !== null) {
        this.uploadingPhoto1 = true;
        await this.uploadFile(this.firstFile);
        this.upload1Complete = true;
      }

      if (this.secondFile !== null) {
        this.uploadingPhoto2 = true;
        await this.uploadFile(this.secondFile);
        this.upload2Complete = true;
      }
    },
    newItem() {
      this.$router.go();
    },
    newItemInSequence() {
      this.desc = '';
      this.length = 1.0;
      this.width = 1.0;
      this.height = 1.0;
      this.selectedMaterials = 0;
      this.selectedServices = null;
      this.addlMaterialsNotes = '';
      this.addlServicesNotes = '';
      this.generalNotes = '';
      this.firstFile = null;
      this.secondFile = null;
      this.currentStep = 1;
      this.addlServices = false;
      this.addlMats = false;
      this.createItemDialog = false;
      this.rerenderMfgAutocomplete += 1;

      this.currentStep = 4;
    },
    truncate(input, length) {
      if (input && input.length > length) {
        let truncated = input.substr(0, length - 3);
        truncated += '...';

        return truncated;
      }

      return input;
    }
  },
};
</script>{}
