<template>
  <v-container fluid>
    <v-dialog v-model="newProjectDialog" max-width="500px">
      <new-cost-estimator-project-form @onProjectCreate="createProject" />
    </v-dialog>
    <v-data-iterator :items="projects" hide-default-footer>
      <template v-slot:header>
        <v-toolbar color="blue darken-1" flat dark>
          <v-toolbar-title>My Projects</v-toolbar-title>
          <v-btn icon @click="showNewProjectDialog">
            <v-icon>
              mdi-plus-box
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-checkbox v-model="includeArchived" label="Include Archived Projects" @change="getProjects" />
        </v-toolbar>
      </template>
      <template v-slot:default="props">
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-row>
          <v-col v-for="item in props.items" :key="item.projectId" cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ item.projectName }}
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content># Items:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.itemCount }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Created On:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.createdAt | dateParse('YYYY-MM-DD') | dateFormat('dddd, MMMM D, YYYY') }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Client:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.subClient !== null ? item.subClient.name : null }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-card-actions>
                <v-row justify="center">
                  <v-col>
                    <v-btn color="primary" text ripple @click="goToProject(item.projectId)">
                      Go to Project
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn color="error" text ripple @click="archiveProject(item.projectId)">
                      Archive Project
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NewCostEstimatorProjectForm from './NewCostEstimatorProjectForm.vue';

export default {
  name: 'CostEstimatorProjects',
  components: {

    NewCostEstimatorProjectForm,
  },
  data: () => ({
    projects: [],
    loading: false,
    newProjectDialog: false,
    includeArchived: false,
    newProject: {},
  }),
  created() {
    this.clientId = this.getClientId();

    this.getProjects();
  },
  methods: {
    ...mapActions('apiClient', ['get', 'post']),
    ...mapGetters('auth', ['getClientId']),
    showNewProjectDialog() {
      this.newProjectDialog = true;
    },
    goToProject(projectId) {
      this.$router.push({ name: 'estimatorProjectView', params: { projectId } });
    },
    async archiveProject(projectId) {
      const url = `/estimator/${projectId}/archive`;

      this.loading = true;

      await this.post({ url: url }).then(
        (response) => {
          if (response.data.successful) {
            this.projects.splice(this.projects.findIndex(e => e.projectId === projectId), 1);
          }
        },
        (error) => {
          console.log(error);
        }
      ).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1500)
      });
    },
    async createProject(project) {
      const url = '/estimator/create';

      this.loading = true;
      await this.post({ url: url, body: project }).then(
        (response) => {
          this.projects.push(response.data);
        },
        (error) => {
          console.log(error);
        }
      ).finally(
        () => {
          this.newProjectDialog = false;

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        }
      );
    },
    async getProjects() {
      const url = `/estimator/all?includeArchived=${this.includeArchived}`;

      await this.get({ url }).then(
        (response) => {
          this.projects = response.data;
        },
        (error) => {
          console.log(error);
        },
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },
  },
}
</script>
