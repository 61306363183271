<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app floating>
      <v-list-item>
        <v-img src="../../public/VM Logo Gray-Blue-White RGB.jpg" alt="Valet Moving" max-height="120" max-width="170" />
      </v-list-item>
      <v-divider />
      <v-list nav>
        <template v-for="(item, idx) in items">
          <v-list-item :key="idx" link :to="item.link" exact>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title :to="item.link">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider />
      <v-btn text color="blue darken-1" ripple @click="$router.push('/changePassword')">
        Change Password
      </v-btn>
      <v-divider />
      <v-btn text color="blue darken-1" ripple @click="logout">
        Logout
      </v-btn>
    </v-navigation-drawer>

    <!-- <v-app-bar
      :clipped-left="$vuetify.breakpoint.mdAndDown"
      app
      color="blue darken-3"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    </v-app-bar> -->
    <v-main>
      <v-container fluid>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    drawer: null,
    fixed: true,
    items: [
      {
        icon: 'mdi-account-circle-outline',
        text: 'My Profile',
        link: '/client'
      },
      {
        icon: 'mdi-folder-plus-outline',
        text: 'Add Pending Inventory',
        link: '/inventory/new',
      },
      // {
      //   icon: 'mdi-cash-fast',
      //   text: 'Cost Estimator',
      //   link: '/charges',
      // },
      {
        icon: 'mdi-cash-fast',
        text: 'Estimator',
        link: '/estimator',
      },
    ],
  }),
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => this.$router.push('/login'));
    },
  },
};
</script>
