<template>
  <v-container>
    <v-dialog
      v-model="changeNotesUnread"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          What's New
        </v-toolbar>
        <v-card-text>
          <div class="pa-12">
            {{ this.changeNotes }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="changeNotesUnread = false" color="blue darken-1">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" align-content="center" justify="center">
      <h1>Terms and Conditions</h1>
    </v-row>
    <v-row align="center" align-content="center" justify="center">
      <h3>You must agree to the following terms and conditions to continue.</h3>
    </v-row>
    <br />
    <br />
    <v-row align="center" align-content="center" justify="center">
      <v-progress-circular v-show="isLoading" indeterminate color="blue" />
    </v-row>
    <div>
      <vue-pdf-embed
        v-if="latestVersionUrl"
        :source="latestVersionUrl"
        @loaded="onLoaded"
      />
    </div>
    <br />
    <br />
    <v-row align="center" align-content="center" justify="center">
      <v-btn v-show="!isLoading" justify="center" @click="acceptTerms" color="blue">
        Accept
      </v-btn>
    </v-row>
  </v-container>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #ccc;
}

.scrollable {
  width: 100vh;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 5%;
}

.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}

.app-content {
  padding: 24px 16px;
}

.right {
  float: right;
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "TermsAndConditionsComponent",
  components: {
    VuePdfEmbed,
  },
  data: () => ({
    latestVersionUrl: null,
    isLoading: false,
    latestTermsId: null,
    changeNotesUnread: true,
    changeNotes: false,
  }),
  created() {
    this.getLatestVersion();
  },
  methods: {
    ...mapActions("apiClient", ["get"]),
    ...mapMutations("auth", ["tc_check_success"]),
    onLoaded() {
      this.isLoading = false;
    },
    async acceptTerms() {
      await this.get({ url: `/users/acceptTerms/${this.latestTermsId}`}).then(
        (response) => {
          if (response.data.successful) {
            this.tc_check_success();
            this.$router.push('/client');
          }
        }
      )
    },
    async getLatestVersion() {
      this.isLoading = true;
      await this.get({ url: "/config/terms" }).then((response) => {
        this.latestTermsId = response.data.id;
        this.changeNotes = response.data.changeNotes;
        this.get({ url: `/assets/item/${response.data.id}` }).then((res) => {
          if (res.data.length > 0) {
            this.latestVersionUrl = `${process.env.VUE_APP_API_BASE_URL}/assets/${res.data[0]}`;
          }
        });
      });
    },
  },
};
</script>
