<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="searchPending"
      :page="page"
      :items-per-page="-1"
      loading-text="loading, please wait"
      no-data-text="There is no pending inventory"
      class="elevation-1"
      @click:row="editItem"
    >
      <template v-slot:progress>
        <v-progress-linear
          v-show="searchPending"
          indeterminate
          rounded
          height="10"
        />
      </template>
      <template v-slot:item.receivedDate="{ item }">
        {{ item.receivedDate.substr(0, 10) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PendingInventoryList',
  data: () => ({
    loading: false,
    page: 0,
    searchPending: false,
    clientId: null,
    headers: [
      {
        text: 'Estimated Arrival Date',
        value: 'receivedDate',
      },
      {
        text: 'Description',
        value: 'description',
        sortable: false,
      },
      {
        text: 'Manufacturer',
        value: 'manufacturer.name',
      },
      {
        text: 'Client',
        value: 'client.name',
      },
      {
        text: 'Sub Client',
        value: 'subClient.name',
      },
    ],
    items: [],
  }),
  created() {
    this.loading = true;

    this.clientId = this.getClientId();

    this.getPendingInventory();

    this.loading = false;
  },
  methods: {
    ...mapActions('apiClient', ['get']),
    ...mapGetters('auth', ['getClientId']),
    editItem(item) {
      this.$router.push({ name: 'pendingInventoryEdit', params: { itemId: item.itemId } });
    },
    async getPendingInventory() {
      this.searchPending = true;

      await this.get({ url: `/inventory/pending/client/${this.clientId}` }).then(
        (response) => {
          this.items = response.data;
        },
        (error) => {
          console.log(error);
        },
      );

      this.searchPending = false;
    },
  },
};
</script>