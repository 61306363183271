import httpClient from '../../utils/httpClient';
import buildRequest from '../../utils/httpRequestUtil';

const apiClient = {
  namespaced: true,
  state: () => ({
    loading: false,
    error: false,
  }),
  getters: {
    showLoading: (state) => state.loading,
    showError: (state) => state.error,
  },
  actions: {
    async get({ commit }, query) {
      commit('show');

      const { endpoint, queryParams } = buildRequest(query);

      return new Promise((resolve, reject) => {
        httpClient.get(endpoint, { params: queryParams }).then(
          (response) => {
            resolve(response);
          }, (error) => {
            commit('showError');
            setTimeout(() => {
              commit('dismissError');
            }, 5000);

            reject(error);
          },
        ).then(() => {
          commit('hide');
        });
      });
    },
    getBlob({ commit }, query) {
      commit('show');

      const { endpoint } = buildRequest(query);

      return new Promise((resolve, reject) => {
        httpClient.get(endpoint, { responseType: 'blob' }).then(
          (response) => {
            resolve(response);
          }, (error) => {
            commit('showError');
            setTimeout(() => {
              commit('dismissError');
            }, 5000);

            reject(error);
          },
        ).then(() => {
          commit('hide');
        });
      });
    },
    async post({ commit }, query) {
      commit('show');

      const { endpoint, body, params } = buildRequest(query);

      return new Promise((resolve, reject) => {
        httpClient.post(endpoint, body, { params }).then(
          (response) => {
            resolve(response);
          }, (error) => {
            commit('showError');
            setTimeout(() => {
              commit('dismissError');
            }, 5000);

            reject(error);
          },
        ).then(() => {
          commit('hide');
        });
      });
    },
    async create({ commit }, query) {
      commit('show');

      const { endpoint, body, params } = buildRequest(query);

      return new Promise((resolve, reject) => {
        httpClient.post(endpoint, body, { params }).then(
          (response) => {
            resolve(response);
          }, (error) => {
            commit('showError');
            setTimeout(() => {
              commit('dismissError');
            }, 5000);

            reject(error);
          },
        ).then(() => {
          commit('hide');
        });
      });
    },
    async update({ commit }, query) {
      commit('show');

      const { endpoint, body, params } = buildRequest(query);

      return new Promise((resolve, reject) => {
        httpClient.put(endpoint, body, { params }).then(
          (response) => {
            resolve(response);
          }, (error) => {
            commit('showError');
            setTimeout(() => {
              commit('dismissError');
            }, 5000);

            reject(error);
          },
        ).then(() => {
          commit('hide');
        });
      });
    },
    async del({ commit }, query) {
      commit('show');

      const { endpoint, body, params } = buildRequest(query);

      return new Promise((resolve, reject) => {
        httpClient.delete(endpoint, body, { params }).then(
          (response) => {
            resolve(response);
          }, (error) => {
            commit('showError');
            setTimeout(() => {
              commit('dismissError');
            }, 5000);
            reject(error);
          },
        ).then(() => {
          commit('hide');
        });
      });
    },
  },
  mutations: {
    show(state) {
      state.loading = true;
    },
    hide(state) {
      state.loading = false;
    },
    showError(state) {
      state.error = true;
    },
    dismissError(state) {
      state.error = false;
    },
  },
};

export default apiClient;
