<template>
  <v-alert
    dismissible
    prominent
    type="success"
    transition="scale-transition"
  >
    {{ name }} was successfully created
  </v-alert>
</template>

<script>
export default {
  name: 'SuccessAlert',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>
